import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, Button, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './ContactUsForm.module.css';

const ContactUsFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        id,
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        emailFail,
      } = fieldRenderProps;

      const namePlaceholder = intl.formatMessage({ id: 'ContactUsForm.namePlaceholder' });
      const nameRequiredMessage = intl.formatMessage({ id: 'ContactUsForm.nameRequired' });
      const nameRequired = validators.required(nameRequiredMessage);

      const lastNamePlaceholder = intl.formatMessage({ id: 'ContactUsForm.lastNamePlaceholder' });
      const lastNameRequiredMessage = intl.formatMessage({ id: 'ContactUsForm.lastNameRequired' });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const emailPlaceholder = intl.formatMessage({ id: 'ContactUsForm.emailPlaceholder' });
      const emailRequiredMessage = intl.formatMessage({ id: 'ContactUsForm.emailRequired' });
      const emailRequired = validators.required(emailRequiredMessage);

      const messagePlaceholder = intl.formatMessage(
        {
          id: 'ContactUsForm.messagePlaceholder',
        }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'ContactUsForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage" id={id}>
          <div className={css.doubleInput}>
            <FieldTextInput
              className={css.field}
              type="text"
              name="name"
              id={formId ? `${formId}.name` : 'name'}
              placeholder={namePlaceholder}
              validate={nameRequired}
            />

            <FieldTextInput
              className={css.field}
              type="text"
              name="lastName"
              id={formId ? `${formId}.lastName` : 'lastName'}
              placeholder={lastNamePlaceholder}
              validate={lastNameRequired}
            />
          </div>

          <FieldTextInput
            className={css.field}
            type="text"
            name="email"
            id={formId ? `${formId}.email` : 'email'}
            placeholder={emailPlaceholder}
            validate={emailRequired}
          />

          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            placeholder={messagePlaceholder}
            validate={messageRequired}
            rows={4}
          />

          {emailFail ? (<div className={css.error}>There was an error sending your message. Please try again later.</div>) : null}
          <div className={submitButtonWrapperClassName}>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="ContactUsForm.submitButtonText" />
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

ContactUsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

ContactUsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContactUsForm = compose(injectIntl)(ContactUsFormComponent);

ContactUsForm.displayName = 'ContactUsForm';

export default ContactUsForm;
