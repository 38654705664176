import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconHeart.module.css';

const IconHeart = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.36538 3.23809C9.50271 3.45614 9.74237 3.58843 10.0001 3.58841C10.2578 3.58839 10.4974 3.45607 10.6347 3.23799C12.2076 0.739586 14.6376 0.313144 16.4974 1.1215C18.3659 1.93368 19.7459 4.0299 19.0798 6.86082C18.4042 9.73189 16.1153 12.2158 13.8824 14.0235C12.7794 14.9164 11.7189 15.6213 10.9341 16.1027C10.5433 16.3425 10.2222 16.526 10 16.6489C9.7778 16.526 9.45666 16.3425 9.06581 16.1027C8.28099 15.6213 7.22052 14.9164 6.11745 14.0234C3.8845 12.2158 1.59561 9.7319 0.920263 6.86087L0.92024 6.86077C0.253928 4.02984 1.63367 1.93378 3.50215 1.12167C5.36188 0.313367 7.79201 0.739797 9.36538 3.23809Z" stroke="#285AC840" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
};

const { string } = PropTypes;

IconHeart.defaultProps = {
  className: null,
  rootClassName: null,
};

IconHeart.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHeart;
