const {Amplify, Storage} = require('aws-amplify')

exports.configureAWS = () => {
  Storage.configure({
    bucket: 'homebaselistings',
    identityPoolId: 'us-east-1:600b4ddb-74fc-4c9a-ace5-fcb08cb5d357',
    region: 'us-east-1',

  });
    Amplify.configure({
      Auth: {
      identityPoolId: 'us-east-1:600b4ddb-74fc-4c9a-ace5-fcb08cb5d357',
      region: 'us-east-1',
      bucket: 'homebaselistings',
    },
    Storage: {
      AWSS3: {
        bucket: 'homebaselistings',
        region: 'us-east-1'
      }
    }
  });
}


