import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    cancelByCustomerInProgress,
    cancelByCustomerError,
    cancelByProviderInProgress,
    cancelByProviderError,
    onAcceptSale,
    onDeclineSale,
    onCancelByCustomer,
    onCancelByProvider,
    showCancelButtons,
    isCustomer,
    listing,
    currentStartDate
  } = props;

  const cancellationPeriods = [
    { key: '24-hours-cancelation', value: 24 },
    { key: '48-hours-cancelation', value: 48 },
    { key: '72-hours-cancelation', value: 72 },
    { key: '7-days-cancelation', value: 168 },
    { key: '14-days-cancelation', value: 336 },
    { key: '30-days-cancelation', value: 720 }
  ]

  const buttonsDisabled = acceptInProgress || declineInProgress || cancelByCustomerInProgress || cancelByProviderInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;

  const cancelErrorMessage = cancelByCustomerError || cancelByProviderError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.cancelSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  const customPolicies = listing.attributes.publicData.customPolicies || [];
  const comparableCurrentStartDate = new Date(currentStartDate).toLocaleDateString()

  function convertToISODate(dateString) {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
}

  let activePolicy;
  if (Array.isArray(customPolicies)) {
    activePolicy = customPolicies.find(policy => {
        const policyStartDate = new Date(policy.startDate).getTime();
        const policyEndDate = new Date(policy.endDate).getTime();

        // Convertir comparableCurrentStartDate a un formato válido
        const validCurrentStartDate = convertToISODate(comparableCurrentStartDate);
        const currentStartDate = new Date(validCurrentStartDate);

        if (isNaN(currentStartDate.getTime())) {
            console.error('Invalid comparableCurrentStartDate after conversion:', comparableCurrentStartDate);
            return false;
        }

        return currentStartDate.getTime() >= policyStartDate && currentStartDate.getTime() <= policyEndDate;
    });
} else {
    console.error('customPolicies is not an array or is undefined');
}

function isCancelButtonEnabled(activePolicy, cancellationPeriods) {
  if (!activePolicy) {
      console.error('No active policy found');
      return false;
  }

  const currentDateTime = new Date();
  const policyEndDateTime = new Date(activePolicy.endDate);
  
  if (isNaN(policyEndDateTime.getTime())) {
      console.error('Invalid end date in activePolicy:', activePolicy.endDate);
      return false;
  }

  // Encuentra el periodo de cancelación
  const cancellationPeriod = cancellationPeriods.find(
      period => period.key === activePolicy.policy
  );

  if (!cancellationPeriod) {
      console.error('No matching cancellation period found for key:', activePolicy.policy);
      return false;
  }

  // Calcula el tiempo límite de cancelación en milisegundos
  const cancellationLimitTime = policyEndDateTime.getTime() - cancellationPeriod.value * 60 * 60 * 1000;

  // Habilitar el botón si la fecha actual está antes del límite de cancelación
  return currentDateTime.getTime() <= cancellationLimitTime;
}

const cancelButtonEnabled = isCancelButtonEnabled(activePolicy, cancellationPeriods);

  return showButtons || showCancelButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
        {cancelErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>

        {showCancelButtons && isCustomer && cancelButtonEnabled ? (
          <PrimaryButton
            className={css.cancel}
            inProgress={cancelByCustomerInProgress}
            disabled={buttonsDisabled}
            onClick={onCancelByCustomer}
          >
            <FormattedMessage id="TransactionPanel.cancelByCustomerButton" />
          </PrimaryButton>
        ) : null}

        {showCancelButtons && !isCustomer && cancelButtonEnabled ? (
          <PrimaryButton
            className={css.cancel}
            inProgress={cancelByProviderInProgress}
            disabled={buttonsDisabled}
            onClick={onCancelByProvider}
          >
            <FormattedMessage id="TransactionPanel.cancelByProviderButton" />
          </PrimaryButton>
        ) : null}

        {showButtons ? (
          <>
          <SecondaryButton
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={onDeclineSale}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>
        <PrimaryButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptSale}
        >
          <FormattedMessage id="TransactionPanel.acceptButton" />
        </PrimaryButton>
        </>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;
