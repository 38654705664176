import React from 'react';
import { bool } from 'prop-types';
import SelectLocationTypeFilterPlain from './SelectLocationTypeFilterPlain';
import SelectLocationTypeFilterPopup from './SelectLocationTypeFilterPopup';

const SelectLocationTypeFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <SelectLocationTypeFilterPopup {...rest} />
  ) : (
    <SelectLocationTypeFilterPlain {...rest} />
  );
};

SelectLocationTypeFilter.defaultProps = {
  showAsPopup: false,
};

SelectLocationTypeFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectLocationTypeFilter;
