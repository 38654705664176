import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import beach from '../../assets/CreationListingProcess/beach.jpg';
import mountain from '../../assets/CreationListingProcess/mountain.jpg';
import city from '../../assets/CreationListingProcess/city.jpg';
import countryside from '../../assets/CreationListingProcess/countryside.jpg';

import css from './SelectLocationTypeFilterPlain.module.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectLocationTypeFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, e) {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    onSelect({ [queryParamName]: option });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      order,
      options,
      queryParamNames,
      initialValues,
      twoColumns,
      useBullets,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;
    const labelClass = initialValue ? css.filterLabelSelected : css.filterLabel;

    const hasBullets = useBullets || twoColumns;
    const optionsContainerClass = classNames(css.optionsContainer, {
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.hasBullets]: hasBullets,
      [css.twoColumns]: twoColumns,
    });

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={labelClass}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelOrder}>{"/"}</span>
            <span className={labelClass}>{label}</span>
          </button>
          <button className={css.clearButton} onClick={e => this.selectOption(null, e)}>
            <FormattedMessage id={'SelectLocationTypeFilter.plainClear'} />
          </button>
        </div>
        <div className={optionsContainerClass}>
          {options.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;

            const optionImage = option.key === 'beach' ? beach : option.key === 'mountain' ? mountain : option.key === 'city' ? city : countryside;

            return (
              // <button
              //   key={option.key}
              //   className={optionClass}
              //   onClick={() => this.selectOption(option.key)}
              // >
              //   <span className={optionBorderClass} />
              //   {option.label}
              // </button>
              <button
                type="button"
                className={classNames(css.item, {
                  [css.checked]: selected 
                })}
                onClick={() => this.selectOption(option.key)}
                key={option.key}
              >
                <img className={css.img} src={optionImage} alt={option.key} />
                <p className={css.itemLabel}>
                  {option.label}
                </p>
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

SelectLocationTypeFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useBullets: false,
};

SelectLocationTypeFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useBullets: bool,
};

export default SelectLocationTypeFilterPlain;
