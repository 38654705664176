import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionCarouselCommunity.module.css';
import SimpleSlider from '../../../../components/SimpleSlider/SimpleSlider'

const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 290px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};



// Section component that's able to show blocks in a carousel
// the number blocks visible is defined by "numColumns" prop.
const SectionCarouselCommunity = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    options,
  } = props;


  const sliderContainerId = `${props.sectionId}-container`;
  const sliderId = `${props.sectionId}-slider`;
  const numberOfBlocks = blocks?.length;
  const hasBlocks = numberOfBlocks > 0;
  const randomNumber = Math.floor(Math.random() * 2500) + 4500;

  // useEffect(() => {
  //   let intervalId = null;
  //   let currentSlide = Math.floor(Math.random() * numberOfBlocks);

  //   const setCarouselWidth = () => {
  //     const windowWidth = window.innerWidth;
  //     const elem = window.document.getElementById(sliderContainerId);
  //     const carouselWidth = elem.clientWidth > windowWidth ? windowWidth : elem.clientWidth;
  //     elem.style.setProperty('--carouselWidth', `${carouselWidth}px`);
  //   };

  //   const scrollRight = () => {
  //     const slider = window.document.getElementById(sliderId);
  //     const slideWidth = numColumns * slider?.firstChild?.clientWidth;
  //     const nextSlide = currentSlide === numberOfBlocks - 1 ? 0 : currentSlide + 1;
  //     slider.scrollLeft = nextSlide * slideWidth;
  //     currentSlide = nextSlide;
  //   };

  //   const startAutoplay = () => {
  //     intervalId = setInterval(scrollRight, randomNumber);

  //   };

  //   const stopAutoplay = () => {
  //       clearInterval(intervalId);
  //       intervalId = null;
  //   };

  //   const handleBloqueSeleccionado = _.debounce(() => {
  //     stopAutoplay()
  //   }, 100);

  //   const handleBloqueAbandonado = _.debounce(() => {
  //     startAutoplay()
  //   }, 100);

  //   blocks.forEach(block => {
  //     document.addEventListener(`bloque${block.blockId}_seleccionado`, handleBloqueSeleccionado);
  //     document.addEventListener(`bloque${block.blockId}_abandonado`, handleBloqueAbandonado);
  //   })
  //   startAutoplay()

  //   return () => {
  //   blocks.forEach(block => {
  //     document.removeEventListener(`bloque${block.blockId}_seleccionado`, handleBloqueSeleccionado);
  //     document.removeEventListener(`bloque${block.blockId}_abandonado`, handleBloqueAbandonado);
  //   })

  //   };

  // }, []);


  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const blockComponents = options?.blockComponents;
  const blockOptions = { blockComponents };


  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const onSlideLeft = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft - slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onSlideRight = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft + slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_LEFT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideLeft(e);
    } else if (e.keyCode === KEY_CODE_ARROW_RIGHT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideRight(e);
    }
  };

  return (
    <SectionContainer
      className={css.customCarouselContainer}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}

    >
      {hasHeaderFields ? (
        <header>
          <Field data={title[0]} className={defaultClasses.description} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null }
      {hasBlocks ? (
        // <div className={css.carouselContainer} id={sliderContainerId}>
        //   {/* <div
        //     className={classNames(css.carouselArrows, {
        //       [css.notEnoughBlocks]: numberOfBlocks <= numColumns,
        //     })}
        //   >
        //     <button className={css.carouselArrowPrev} onClick={onSlideLeft} onKeyDown={onKeyDown}>
        //       ‹
        //     </button>
        //     <button className={css.carouselArrowNext} onClick={onSlideRight} onKeyDown={onKeyDown}>
        //       ›
        //     </button> */}
        //   {/* </div> */}
        //   <div className={[getColumnCSS(numColumns)]} id={sliderId}>
        //     <BlockBuilder
        //       rootClassName={css.block}
        //       // ctaButtonClass={defaultClasses.ctaButton}
        //       blocks={blocks}
        //       responsiveImageSizes={getResponsiveImageSizes(numColumns)}
        //       options={blockOptions}
        //     />
        //   </div>
        // </div>
        <div>
          hola
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCarouselCommunity.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  options: null,
};

SectionCarouselCommunity.propTypes = {
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionCarouselCommunity;
