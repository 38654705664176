import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { ensureListing } from '../../util/data';
import { EditListingWorkspaceForm } from '../../forms';

import css from './EditListingWorkspacePanel.module.css';

const FEATURES_NAME = 'workspace';

const EditListingWorkspacePanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backButtonText,
    redirectAfterPrevTab,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const panelTitle = (
    <FormattedMessage id="EditListingWorkspacePanel.title" />
  );

  const workspace = publicData && publicData.workspace;
  const { noworkstations, keyboardandmouse, monitor } = publicData;
  const initialValues = { workspace, noworkstations, keyboardandmouse, monitor };

  return (
    <div className={classes}>
      <div className={css.header}>
        <h1 className={css.title}>{panelTitle}</h1>
        <p className={css.number}>
          03
        </p>
      </div>
      <EditListingWorkspaceForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={initialValues}
        onSubmit={values => {
          const { workspace = [], noworkstations, ...rest } = values;

          const updatedValues = {
            publicData: { 
              workspace, 
              noworkstations: noworkstations ? parseInt(noworkstations) : 0,
              ...rest
            },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        backButtonText={backButtonText}
        redirectAfterPrevTab={redirectAfterPrevTab}
        {...rest}
      />
    </div>
  );
};

EditListingWorkspacePanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingWorkspacePanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingWorkspacePanel;
