import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { PrimaryButton as Button, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingWorkspaceForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        invalid,
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        values,
        backButtonText,
        redirectAfterPrevTab,
        isNewListingFlow,
        backToListing,
        backToListingText,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingWorkspaceForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingWorkspaceForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('workspace', filterConfig);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <div className={css.label}>
              <FormattedMessage id="EditListingWorkspaceForm.title" />
            </div>

          <FieldCheckboxGroup className={css.features} id={name} name={name} options={options} switchIcon={true} values={values}/>

          <div className={css.buttonWrapper}>
                <Button
                  className={css.backButton}
                  type="button"
                  inProgress={submitInProgress}
                  onClick={redirectAfterPrevTab}
                >
                  {backButtonText}
                </Button>
                <div className={css.buttonWrapper}>
                  {!isNewListingFlow && (
                    <Button
                      className={css.listingButton}
                      type="button"
                      inProgress={submitInProgress}
                      onClick={backToListing}
                    >
                      {backToListingText}
                    </Button>
                  )}
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
                </div>
              </div>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingWorkspaceForm = EditListingFeaturesFormComponent;

export default EditListingWorkspaceForm;
