import React, { Component, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { FieldTextInput, Form, PrimaryButton as Button, InlineTextButton, IconClose } from '../../components';
import { required, composeValidators } from '../../util/validators';

import ManageAvailabilityCalendar from './ManageAvailabilityCalendar';

import tooltipIcon from '../../assets/CreationListingProcess/tooltip.png'
import filledTooltipIcon from '../../assets/CreationListingProcess/filledTooltip.png'

import css from './EditListingAvailabilityForm.module.css';

export class EditListingAvailabilityFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
    };
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            rootClassName,
            disabled,
            ready,
            handleSubmit,
            //intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateError,
            updateInProgress,
            availability,
            availabilityPlan,
            listingId,
            backButtonText,
            redirectAfterPrevTab,
            backToListing,
            backToListingText,
            isNewListingFlow
          } = formRenderProps;

          const errorMessage = updateError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />
            </p>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              <label className={css.label}>
                <FormattedMessage id="EditListingAvailabilityForm.label" />
              </label>

              <div className={css.inputHolder}>
                <FieldTextInput
                  className={css.inputWrapper}
                  inputRootClass={css.input}
                  type="time"
                  id="checkin"
                  name="checkin"
                  label="Check-in time"
                  validate={composeValidators(required('Please enter a check-out time'))}
                />

                <FieldTextInput
                  className={css.inputWrapper}
                  inputRootClass={css.input}
                  type="time"
                  id="checkout"
                  name="checkout"
                  label="Check-out time"
                  validate={composeValidators(required('Please enter a check-out time'))}
                />
              </div>

              <div className={css.tooltip}>
                <InlineTextButton type="button" onClick={() => this.setState({tooltip: !this.state.tooltip})} className={css.tooltip}>
                  <FormattedMessage id="EditListingAvailabilityForm.tooltipTitle"
                    values={{ icon: <img src={tooltipIcon} alt="tooltip" /> }}
                  />
                </InlineTextButton>

                <div className={classNames(css.tooltipContainer, {
                  [css.tooltipContainerActive]: this.state.tooltip,
                })}>
                  <div className={css.tooltipHeader}>
                    <FormattedMessage id="EditListingAvailabilityForm.tooltip"
                      values={{ icon: <img className={css.icon} src={filledTooltipIcon} alt="tooltip" /> }}
                    />

                    <InlineTextButton type="button" onClick={() => this.setState({tooltip: !this.state.tooltip})} className={css.close}>
                      <IconClose />
                    </InlineTextButton>
                  </div>
                  <div className={css.tooltipContent}>
                    <p className={css.tooltipDescription}>
                      <FormattedMessage id="EditListingAvailabilityForm.tooltipContentOne" />
                    </p>
                  </div>
                </div>
              </div>

              <label className={css.label}>
                <FormattedMessage id="EditListingAvailabilityForm.proTip" values={{ lineBreak: <br />, custom: <span className={css.emph}>"<FormattedMessage id="EditListingAvailabilityPanel.proTipCustom" />"</span> }} />
              </label>

              <div className={css.calendarWrapper}>
                <ManageAvailabilityCalendar
                  availability={availability}
                  availabilityPlan={availabilityPlan}
                  listingId={listingId}
                />
              </div>

              <div className={css.buttonWrapper}>
                <Button
                  className={css.backButton}
                  type="button"
                  inProgress={submitInProgress}
                  onClick={redirectAfterPrevTab}
                >
                  {backButtonText}
                </Button>
                
                <div className={css.buttonWrapper}>
                  {!isNewListingFlow && (
                    <Button
                      className={css.listingButton}
                      type="button"
                      inProgress={submitInProgress}
                      onClick={backToListing}
                    >
                      {backToListingText}
                    </Button>
                  )}
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingAvailabilityFormComponent.defaultProps = {
  updateError: null,
};

EditListingAvailabilityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  availability: object.isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
};

export default compose(injectIntl)(EditListingAvailabilityFormComponent);
