import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';

import { propTypes } from '../../util/types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { IconArrowHead, ResponsiveImage } from '../../components';
import ImageCarouselMobile from './ImageCarouselMobile';

// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import './image-gallery.css';

import css from './ImageCarousel.module.css';

const IMAGE_GALLERY_OPTIONS = {
  showPlayButton: false,
  disableThumbnailScroll: true,
  showThumbnails: false,
  showFullscreenButton: false,
  slideDuration: 350,
};

const ListingImageGallery = props => {
  const { intl, rootClassName, className, images, imageVariants } = props;

  const items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ImageCarousel.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      image: img,
    };
  });

  // If no image is given, rendere empty image.
  if (items.length === 0) {
    const classes = classNames(rootClassName || css.noImage, className);
    return <ResponsiveImage className={classes} image={null} variants={[]} alt="" />;
  }

  const classes = classNames(rootClassName || css.root, className);
  const [reorderedItems, setReorderedItems] = useState(images);
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  // Show a grid of thumbnails first and show the gallery when a thumbnail is clicked
  return (
    <>
      <div className={css.thumbnailGrid}>
        {items.map((item, i) => {
          return (
            <div 
              key={i} 
              className={classNames(css.thumbnailWrapper, {
                [css.hide]: hasBeenClicked,
              })}
              onClick={() => {
                // Reorder the images so that the selected image is first
                setReorderedItems([...images.slice(i), ...images.slice(0, i)]);
                setHasBeenClicked(true);
              }}
            >
              <div className={css.aspectWrapper}>
                <ResponsiveImage
                  rootClassName={css.thumbnail}
                  image={item.image}
                  alt={item.alt}
                  variants={imageVariants}
                  sizes="(max-width: 767px) 100vw, 80vw"
                />
              </div>
            </div>
          );
        })}
      </div>
      {/* Show the gallery when a thumbnail is clicked */}
      <div className={classNames(css.gallery,{
        [css.hide]: !hasBeenClicked,
      })}>
          <div className={css.closeButton} onClick={() => {
            // Reset the image order
            setReorderedItems(images);
            setHasBeenClicked(false);
          }}>
            <IconArrowHead direction="left" size="big" className={css.icon}/> <span className={css.text}>Back</span>
          </div>
          <ImageCarouselMobile
            rootClassName={css.selectedCarousel}
            images={reorderedItems}
            imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
          />
        </div>
    </>
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,
  imageVariants: arrayOf(string).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
