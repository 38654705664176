import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, IconHeart } from '../../components';
import { removePostalCodeAndShortenAddress } from '../../util/address';

import city from '../../assets/ListingCard/apartment.png';
import country from '../../assets/ListingCard/park.png';
import mountain from '../../assets/ListingCard/filter_hdr.png';
import beach from '../../assets/ListingCard/beach_access.png';

import bath from '../../assets/ListingCard/bathTub.png';
import bed from '../../assets/ListingCard/bed.png';
import guest from '../../assets/ListingCard/guest.png';
import work from '../../assets/ListingCard/workDesk.png';

import verified from '../../assets/verified.png';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing, isHorizontal, updateInProgress,
    updateProfileError, isAuthenticated, currentUser } = props;
    
  const classes = classNames(rootClassName || css.root, className, {
    [css.rootHorizontal]: isHorizontal,
  });
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const { publicData, metadata } = currentListing.attributes || {};
  const { category, location, monthlyDiscount, weeklyDiscount } = publicData || {};
  const { verified: isVerified } = metadata || {};

  const { privateData: userPrivateData } = currentUser?.attributes?.profile || {};
  const { whishlist = [] } = userPrivateData || {};
  const isWishlisted = whishlist && whishlist.includes(id);

  const typeIcon = category === 'city' ? city : category === 'countryside' ? country : category === 'mountain' ? mountain : category === 'beach' ? beach : city;
  const typeId = category === 'city' ? 'city' : category === 'countryside' ? 'countryside' : category === 'mountain' ? 'mountain' : category === 'beach' ? 'beach' : 'city';

  const hasDiscount = monthlyDiscount || weeklyDiscount;

  // Split the address to just show city, state and countryside
  // example: CN Tower, 301 Front St W, Toronto, Ontario M5V 2T6, Canada
  // result: Toronto, Ontario, Canada
  const address = removePostalCodeAndShortenAddress(location?.address);

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={classNames(css.threeToTwoWrapper, {
          [css.threeToTwoWrapperHorizontal]: isHorizontal,
        })}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={classNames(css.aspectWrapper, {
          [css.aspectWrapperHorizontal]: isHorizontal,
        })}>
          <ResponsiveImage
            rootClassName={classNames(css.rootForImage, {
              [css.rootForImageHorizontal]: isHorizontal,
            })}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={classNames(css.info, {
        [css.infoHorizontal]: isHorizontal, 
      })}>
        <div className={css.headerInfo}>
          <div className={css.type}>
            <img src={typeIcon} alt="type" className={css.icon}/>
            <span className={css.typeId}>
              <FormattedMessage id={`ListingCard.${typeId}`} />
            </span>
          </div>
          {isVerified && (
            <img src={verified} alt="verified" className={css.verified}/>
          )}
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.location}>
            <FormattedMessage id={`ListingCard.location`} values={{ city: <span className={css.cityName}>{address}</span> }}/> | {publicData?.squareFeet} sqft
          </div>
        </div>
        <div className={css.amenities}>
          <div className={css.amenity}>
            <img src={guest} alt="guest" className={css.amenityIcon}/>
            <span className={css.amenityValue}>{publicData.noOfGuests}</span>
          </div>
          <div className={css.amenity}>
            <img src={bed} alt="bed" className={css.amenityIcon}/>
            <span className={css.amenityValue}>{publicData.noOfBedrooms}</span>
          </div>
          <div className={css.amenity}>
            <img src={bath} alt="bath" className={css.amenityIcon}/>
            <span className={css.amenityValue}>{publicData.noOfBathrooms}</span>
          </div>
          <div className={css.amenity}>
            <img src={work} alt="work" className={css.amenityIcon}/>
            <span className={css.amenityValue}>{publicData.noworkstations}</span>
          </div>
        </div>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
        <div className={css.discounts}>
          <div className={css.discount}>
            {hasDiscount && monthlyDiscount && (
              <FormattedMessage id="ListingCard.longStayDiscountTag" />
            )}
          </div>
        </div>
      </div>
      <button
        className={css.favoriteButton}
        type="button"
        onClick={e => {
          e.preventDefault();

          const { onFavoriteListing, isAuthenticated } = props;

          if (onFavoriteListing && isAuthenticated) {

            onFavoriteListing(currentListing.id);
          }

          if (!isAuthenticated) {
            const { history } = props;
            history.push('/login');
          }
        }}
      >
        <IconHeart className={classNames(css.favoriteIcon, {
          [css.clicked]: isWishlisted
        })} />
      </button>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
