import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, Button, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './Reviews.module.css';

const Review = props => {
  const { review, intl } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { day: '2-digit', month: '2-digit', year: '2-digit' });

  return (
    <div className={css.review}>
      <div className={css.user}>
        <Avatar className={css.avatar} user={review.author} />
        <UserDisplayName user={review.author} intl={intl} className={css.name}/>
        <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
          <span className={css.desktopReviewDate}>{dateString}</span>
      </div>
      <span className={css.mobileReviewDate}>{dateString}</span>
      <div>
        <p className={css.reviewContent}>{review.attributes.content}</p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, seeMore } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [showNumber, setShowNumber] = React.useState(3);

  return (
    <ul className={classes}>
      {reviews.map((r, index) => {
        if (index < showNumber) {
          return (
            <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
              <Review review={r} intl={intl} />
            </li>
          );
        }
      })}
      {seeMore && showNumber < reviews.length && (
        <Button className={css.seeMoreButton} onClick={() => {
          setShowNumber(showNumber + 3);
        }}>
          <FormattedMessage id="Reviews.seeMoreReviews" />
        </Button>
      )}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
