import React from 'react'
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../containers/PageBuilder/Field';
import NamedLink from '../NamedLink/NamedLink';

import css from './YouPick.module.css';


export default function YouPick(props) {
  const { rootClassName, className, intl, title, options } = props;
  const classes = classNames(rootClassName || css.root, className);

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  return (
    <div className={classes}>
      <div className={css.youPick}>
          <h2 className={css.youPickTitle}>
            <Field data={title} className={classNames(css.heroMainTitle)} options={fieldOptions} />
          </h2>
        <div className={css.youPickCardContainer}>
          <NamedLink name="SearchPage" to={{ search: "?pub_category=beach"}} className={css.youPickCardBeach}>
            <div className={css.youPickEmpty}>
            </div>
            <div className={css.youPickCardFooter}>
              Beach
            </div>
          </NamedLink>
          <NamedLink name="SearchPage" to={{ search: "?pub_category=mountain"}} className={css.youPickCardMountain}>
            <div className={css.youPickEmpty}>
            </div>
            <div className={css.youPickCardFooter}>
              Mountain
            </div>
          </NamedLink>
          <NamedLink name="SearchPage" to={{ search: "?pub_category=city"}} className={css.youPickCardCity}>
            <div className={css.youPickEmpty}>
            </div>
            <div className={css.youPickCardFooter}>
              City
            </div>
          </NamedLink>
          <NamedLink name="SearchPage" to={{ search: "?pub_category=countryside"}} className={css.youPickCardCountrySide}>
            <div className={css.youPickEmpty}>
            </div>
            <div className={css.youPickCardFooter}>
              Countryside
            </div>
          </NamedLink>
        </div>
      </div>
    </div>
  )
}
