import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleCheck.module.css';

const IconCircleCheck = props => {
  const { className, rootClassName, brand } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none" className={classes}>
      <path d="M32 63.9654C14.3377 63.9654 0 49.6623 0 32C0 14.3377 14.3377 0 32 0C49.6623 0 64 14.3377 64 32C64 49.6623 49.6623 64 32 64V63.9654ZM32 58.4935C46.6493 58.4935 58.4935 46.6147 58.4935 32C58.4935 17.3853 46.6147 5.47186 32 5.47186C17.3853 5.47186 5.47186 17.3506 5.47186 32C5.47186 46.6493 17.3506 58.4935 31.9654 58.4935H32Z" fill="#14D282"/>
      <path d="M46.6864 22.8569L46.8942 23.0647C47.8985 24.0691 47.8985 25.7314 46.8942 26.7357L29.8898 43.7401C28.8855 44.7444 27.2232 44.7444 26.2189 43.7401L26.0111 43.5323C25.0067 42.5279 25.0067 40.8656 26.0111 39.8613L43.0154 22.8569C44.0197 21.8526 45.6821 21.8526 46.6864 22.8569Z" fill="#14D282"/>
      <path d="M29.2655 44.1558L29.0577 44.3636C28.0534 45.3679 26.391 45.3679 25.3867 44.3636L16.14 35.1168C15.1356 34.1125 15.1356 32.4502 16.14 31.4458L16.3478 31.238C17.3521 30.2337 19.0144 30.2337 20.0188 31.238L29.2655 40.4848C30.2698 41.4891 30.2698 43.1515 29.2655 44.1558Z" fill="#14D282"/>
    </svg>
  )
};

IconCircleCheck.defaultProps = {
  className: null,
  rootClassName: null,
  brand: 'default',
};

IconCircleCheck.propTypes = {
  className: string,
  rootClassName: string,
  brand: string,
};

export default IconCircleCheck;
