import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockAccordionItem.module.css';
import { IconAdd } from '../../../../components';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockAccordionItem = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    index
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={css.imageContainer}
        options={options}
      />
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <div 
            className={css.titleContainer}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <h3 className={css.index}>{index < 10 ? `0${index + 1}` : index + 1}</h3>
            <Field data={title} options={options} className={css.title} />
            <div className={css.addIcon}>
              <IconAdd />
            </div>
          </div>
          {isExpanded && (
            <>
              <Field data={text} options={options} className={css.description} />
              <Field data={callToAction} className={ctaButtonClass} options={options} />
            </>
          )}
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockAccordionItem.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockAccordionItem.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockAccordionItem;
