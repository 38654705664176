import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const [step, setStep] = useState(0);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} className={css.link} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const typeOptions = findOptionsForSelectFilter('type', config.custom.filters);

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, building } = location;
    
    return {
      building,
      locationInput: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, origin: geolocation },
        }
        : null,
    };
  }

  return (
    <div className={classes}>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{
          title,
          description,
          category: publicData.category,
          noOfGuests: publicData.noOfGuests,
          noOfBedrooms: publicData.noOfBedrooms,
          noOfBathrooms: publicData.noOfBathrooms,
          type: publicData.type,
          squareFeet: publicData.squareFeet,
          exclusive: publicData.exclusive,
          strPolicyNumber: publicData.strPolicyNumber,
          ...getInitialValues()
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description, category, locationInput, building, exclusive, noOfGuests, noOfBedrooms, noOfBathrooms, type, squareFeet, strPolicyNumber } = values;

          const {
            selectedPlace: { address, origin },
          } = locationInput;
          
          const updateValues = {
            title: title.trim(),
            description,
            geolocation: origin,
            publicData: {
              category,
              location: { address, building },
              noOfGuests: parseInt(noOfGuests),
              noOfBedrooms: parseInt(noOfBedrooms),
              noOfBathrooms: parseInt(noOfBathrooms),
              exclusive,
              type,
              squareFeet,
              strPolicyNumber
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        typeOptions={typeOptions}
        step={step}
        setStep={setStep}
        panelTitle={panelTitle}
        {...rest}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
