import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Form, PrimaryButton as Button, FieldTextInput, IconClose, AddImages, ValidationError, IconAdd } from '../../components';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import css from './EditListingLocationForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const { UUID } = sdkTypes;

const { configureAWS } = require('../../aws')

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        backButtonText,
        redirectAfterPrevTab,
        onSubmitImages,
        surroundings,
        backToListing,
        backToListingText,
        isNewListingFlow
      } = formRenderProps;

      const [imageUploadRequested, setImageUploadRequested] = useState(false);

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const buildingMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.typeSomething' },
        { optionalText: optionalText }
      );

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const [images, setImages] = useState([]);

      const deleteCertificate = async (file) => {
        const { uuid } = file
        await Storage.remove(uuid)
        getCertificates()
      }

      useEffect(() => {
        configureAWS()
      }, []);

      useEffect(() => {
        if (surroundings?.length > 0) {
          getCertificates()
        }
      }, [surroundings])

      const getCertificates = async () => {
        let imagesA = []
        for (let i = 0; i < surroundings.length; i++) {
          await Storage.get(`surroundings/${surroundings[i]}`)
            .then(result => {
              console.log('result', result)
              console.log("images | " + surroundings[i], images)
              imagesA.push({
                id: new UUID(surroundings[i]),
                attributes: {
                  variants: {
                    "landscape-crop": {
                      url: result,
                      width: 100,
                    }
                  }
                }
              })
            })
            .catch(err => console.log('error', err))
        }
        setImages(imagesA)
      }

      const uploadFiles = async (file) => {
        let randName = new UUID(uuidv4())
        await Storage.put(`surroundings/${randName.uuid}`, file, {
          resumable: true,
          completeCallback: (event) => {
            const { key } = event
            const newValues = {
              publicData: {
                surroundings: [...surroundings, key.slice(13)] // 13 it's the length of the path 'surroundings/'
              }
            }
            onSubmitImages(newValues)
            setImageUploadRequested(false)
          },
          errorCallback: (err) => {
            console.log('error', err)
          }
        })
      }

      const chooseImageText = (
        <span className={css.chooseImageText}>
          <span className={css.chooseImageTwo}>
            <IconAdd className={css.addIcon} />
          </span>
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          

          {imageUploadRequested && (
                <div className={css.loaderWrapper}>
                  <div className={css.loader} />
                </div>
              )}

          {errorMessage}
          {errorMessageShowListing}

          <div className={css.label}>
            <FormattedMessage id="EditListingLocationForm.title" />
          </div>

          <FieldTextInput
            className={css.building}
            inputRootClass={css.input}
            type="textarea"
            name="nearby"
            id="nearby"
            label={buildingMessage}
            placeholder={buildingMessage}
            rows={3}
          />

          <AddImages
            className={css.imagesField}
            images={images}
            thumbnailClassName={css.thumbnail}
            savedImageAltText={intl.formatMessage({
              id: 'EditListingPhotosForm.savedImageAltText',
            })}
            onRemoveImage={deleteCertificate}
            displayRule={() => { return true }}
          >
            <Field
              id="addImage"
              name="addImage"
              accept={ACCEPT_IMAGES}
              form={null}
              label={chooseImageText}
              type="file"
              disabled={false}
            >
              {fieldprops => {
                const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                const { name, type } = input;
                const onChange = async e => {
                  const file = e.target.files[0];

                  if (file) {
                    setImageUploadRequested(true)
                    uploadFiles(file)
                  }
                };

                const inputProps = { accept, id: name, name, onChange, type };
                return (
                  <div className={classNames(css.addImageWrapper)}>
                    <div className={css.aspectRatioWrapper}>
                      {fieldDisabled ? null : (
                        <input {...inputProps} className={css.addImageInput} />
                      )}
                      <label htmlFor={name} className={css.addImage}>
                        {label}
                      </label>
                    </div>
                  </div>
                );
              }}
            </Field>

            <Field
              component={props => {
                const { input, meta } = props;
                return (
                  <div className={css.imageRequiredWrapper}>
                    <input {...input} />
                    <ValidationError fieldMeta={meta} />
                  </div>
                );
              }}
              name="images"
              type="hidden"
            //validate={composeValidators(nonEmptyArray("imageRequiredMessage"))}
            />
          </AddImages>

          <div className={css.buttonWrapper}>
            <Button
              className={css.backButton}
              type="button"
              inProgress={submitInProgress}
              onClick={redirectAfterPrevTab}
            >
              {backButtonText}
            </Button>
            <div className={css.buttonWrapper}>
              {!isNewListingFlow && (
                <Button
                  className={css.listingButton}
                  type="button"
                  inProgress={submitInProgress}
                  onClick={backToListing}
                >
                  {backToListingText}
                </Button>
              )}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
