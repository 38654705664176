import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockHorizontalImageCard.module.css';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);

  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} imageClassName={css.image} />
    </div>
  ) : null;
};

const BlockHorizontalImageCard = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options); 7

  // Check if id has blog on it
  const isBlog = blockId.includes('blog');

  return (
    <BlockContainer id={blockId} className={classNames(classes, {
      [css.blog]: isBlog,
    })}>
      <Field data={callToAction} className={css.cta} options={options}>

        <FieldMedia
          media={media}
          sizes={responsiveImageSizes}
          className={css.imageContainer}
          options={options}
        />
        {hasTextComponentFields ? (
          <div className={classNames(textClassName, css.text)}>

            <Field data={title} options={options} className={classNames({
              [css.blogTitle]: isBlog,
            })} />
            <Field data={text} options={options} className={classNames({
              [css.blogText]: isBlog,
            })} />
          </div>
        ) : null}
      </Field>

    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockHorizontalImageCard.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockHorizontalImageCard.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockHorizontalImageCard;
