import React from 'react'
import classNames from 'classnames';
import css from './EarnMoney.module.css'
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';

import img from '../../assets/LandingPage/snow.png'
import { NamedLink } from '../';

const EarnMoney = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.content}>
        <h2 className={css.title}>
          Earn more money <br /> <span> from your property</span>
        </h2>
        <ul className={css.list}>
          <li>
            Host trusted professionals with verified LinkedIn profiles
          </li>
          <li>
            Host guests for extended periods
          </li>
          <li>
            Increase your rental income
          </li>
        </ul>

        <NamedLink name="CMSPage" params={{ pageId: "become-a-host" }} className={css.customButtonElement}>
          Become a host
        </NamedLink>
      </div>
      <div className={css.imageContainer}>
        <img src={img} className={css.image} />
      </div>
    </div>
  )
}


const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

EarnMoney.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 2,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

EarnMoney.propTypes = {
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default EarnMoney;
