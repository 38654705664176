import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { PrimaryButton as Button, Form, FieldCurrencyInput, FieldCheckbox, FieldTextInput, InlineTextButton, IconClose } from '../../components';
import css from './EditListingPricingForm.module.css';

import tooltipIcon from '../../assets/CreationListingProcess/tooltip.png'
import filledTooltipIcon from '../../assets/CreationListingProcess/filledTooltip.png'

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        backButtonText,
        redirectAfterPrevTab,
        isDraft,
        backToListing,
        backToListingText,
        isNewListingFlow,
        form
      } = formRenderProps;

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const weekendPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.weekendPriceInputPlaceholder',
      });

      const cleaningFeePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.cleaningFeeInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const weeklyDiscountMessage = intl.formatMessage({
        id: 'EditListingPricingForm.weeklyDiscount',
      });

      const monthlyDiscountMessage = intl.formatMessage({
        id: 'EditListingPricingForm.monthlyDiscount',
      });

      const minimumStayMessage = intl.formatMessage({
        id: 'EditListingPricingForm.minimumStay',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const [tooltip, setTooltip] = useState(false);

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            inputRootClass={css.input}
            autoFocus
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

            <FieldCurrencyInput
              id="weekendPrice"
              name="weekendPrice"
              className={css.priceInput}
              inputRootClass={css.input}
              autoFocus
              placeholder={weekendPricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
            />

          <div className={css.inputHolder}>
            <div className={css.onTop}>
              <label className={css.dLabel}>{minimumStayMessage}</label>
            </div>

            <FieldTextInput
              id="minimumStay"
              name="minimumStay"
              className={classNames(css.half)}
              inputRootClass={css.input}
              type="number"
              placeholder={intl.formatMessage({ id: 'EditListingPricingForm.minimumStayPlaceholder' })}
            />
          </div>

          <label className={css.label}>
            <FormattedMessage id="EditListingPricingForm.label" />
          </label>

          <FieldCurrencyInput
            id="cleaningFee"
            name="cleaningFee"
            className={css.priceInput}
            inputRootClass={css.input}
            autoFocus
            placeholder={cleaningFeePlaceholderMessage}
            currencyConfig={config.currencyConfig}
          />

          <div className={css.inputHolder}>
            <div className={css.onTop}>
              <label className={css.dLabel}>{weeklyDiscountMessage}</label>
            </div>

            <FieldTextInput
              id="weeklyDiscount"
              name="weeklyDiscount"
              className={classNames(css.half, css.discountInput)}
              inputRootClass={css.input}
              type="number"
              placeholder={intl.formatMessage({ id: 'EditListingPricingForm.discountPlaceholder' })}
            />
          </div>
          <div className={css.inputHolder}>
            <div className={css.onTop}>
              <label className={css.dLabel}>{monthlyDiscountMessage}</label>
            </div>

            <FieldTextInput
              id="monthlyDiscount"
              name="monthlyDiscount"
              className={classNames(css.half, css.discountInput)}
              inputRootClass={css.input}
              type="number"
              placeholder={intl.formatMessage({ id: 'EditListingPricingForm.discountPlaceholder' })}
            />
          </div>

          <div className={css.tooltip}>
            <InlineTextButton type="button" onClick={() => setTooltip(!tooltip)} className={css.tooltipTitle}>
              <FormattedMessage id="EditListingPricingForm.tooltip"
                values={{ icon: <img src={tooltipIcon} alt="tooltip" /> }}
              />
            </InlineTextButton>

            <div className={classNames(css.tooltipContainer, {
              [css.tooltipContainerActive]: tooltip,
            })}>
              <div className={css.tooltipHeader}>
                <FormattedMessage id="EditListingPricingForm.tooltip"
                  values={{ icon: <img className={css.icon} src={filledTooltipIcon} alt="tooltip" /> }}
                />

                <InlineTextButton type="button" onClick={() => setTooltip(!tooltip)} className={css.close}>
                  <IconClose />
                </InlineTextButton>
              </div>
              <div className={css.tooltipContent}>
                <p className={css.tooltipDescription}>
                  <FormattedMessage id="EditListingPricingForm.tooltipContent"
                  //values={{ icon: <img className={css.icon} src={iconOne}/>}}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className={css.header}>
            <h3 className={css.customPricingTitle}>
              <FormattedMessage id="EditListingPricingForm.customPricingTitle" />
            </h3>
            <p className={css.customPricingDescription}>
              <FormattedMessage id="EditListingPricingForm.customPricingDescription" />
            </p>
          </div>

            {values?.customPricing?.length > 0 && values?.customPricing.map((item, index) => (
            <div className={css.threeColumns} key={index}>
                <FieldCurrencyInput
                  id={`customPricing[${index}].price`}
                  name={`customPricing[${index}].price`}
                  className={css.priceInput}
                  inputRootClass={css.input}
                  autoFocus
                  label={"Price"}
                  placeholder={"Price"}
                  currencyConfig={config.currencyConfig}
                />

                <FieldTextInput
                  id={`customPricing[${index}].startDate`}
                  name={`customPricing[${index}].startDate`}
                  type="date"
                  label="Start Date"
                  inputRootClass={css.input}
                />

                <FieldTextInput
                  id={`customPricing[${index}].endDate`}
                  name={`customPricing[${index}].endDate`}
                  type="date"
                  label="End Date"
                  inputRootClass={css.input}
                />
                <IconClose onClick={() => {
                  const newCustomPricing = values.customPricing.filter((_, i) => i !== index);
                  form.change('customPricing', newCustomPricing);
                }
                } className={css.close}/>

          </div>
            ))}
          <InlineTextButton
              className={css.addCustomPricingButton}
              type="button"
              onClick={() => {
                if (values?.customPricing?.length > 0) {
                  form.change('customPricing', [...values.customPricing, { price: null, startDate: null, endDate: null }]);
                } else {
                  form.change('customPricing', [{ price: null, startDate: null, endDate: null }]);
                }
              }}
            >
              <FormattedMessage id="EditListingPricingForm.addCustomPricingButton" />
            </InlineTextButton>


          <div className={css.buttonWrapper}>
            <Button
              className={css.backButton}
              type="button"
              inProgress={submitInProgress}
              onClick={redirectAfterPrevTab}
            >
              {backButtonText}
            </Button>

            <div className={css.buttonWrapper}>
              {!isNewListingFlow && (
                <Button
                  className={css.listingButton}
                  type="button"
                  inProgress={submitInProgress}
                  onClick={backToListing}
                >
                  {backToListingText}
                </Button>
              )}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
