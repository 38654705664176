import React, { useEffect } from 'react';
import Field, { hasDataInFields } from '../../containers/PageBuilder/Field'

import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import BlockContainer from '../../containers/PageBuilder/BlockBuilder/BlockContainer/BlockContainer';
// import field, { hasDataInFields } from '../../Field';

import css from './CarouselThirdBlock.module.css'

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};
import white from '../../assets/Community/white.svg'

const CarouselThirdBlock = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);


  useEffect(() => {
    // código que se ejecuta después de que el componente se ha montado en el DOM
    const bloque = document.getElementById(blockId)
      bloque.addEventListener('mouseenter', () => {
        const eventoSeleccion = new CustomEvent(`bloque${blockId}_seleccionado`);
        document.dispatchEvent(eventoSeleccion);
      })

      bloque.addEventListener('mouseleave', () => {
        const eventoAbandono = new CustomEvent(`bloque${blockId}_abandonado`);
        document.dispatchEvent(eventoAbandono)
      })
  }, []);



  return (
    <BlockContainer id={blockId} className={[classes, css.thirdBlock, blockId]}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      <div className={css.blockQuotes}>
        <img src={white} />
      </div>
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} className={css.title}/>
          <Field data={text} options={options} />
          <Field data={callToAction} className={ctaButtonClass} options={options} />
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

CarouselThirdBlock.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

CarouselThirdBlock.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default CarouselThirdBlock;
