import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';

import css from './P.module.css';

export const P = React.forwardRef((props, ref) => {
  const { className, rootClassName, ...otherProps } = props;

  const classes = classNames(rootClassName || css.p, className);

  let par = otherProps.children.map(child => {
    // Check for matches of the form {{color}}text{{color}}
    const keyFormat = /\{\{(green|blue|white|black|marketColor)\}\}([^]*?)\{\{\1\}\}/g;
    const parts = [];
    let match;

    let lastIndex = 0;

    if (typeof child !== 'string') {
      return child;
    }

    const childChecked = typeof child === 'string' ? child : child.props.children;

    const TAG = typeof child === 'string' ? 'span' : child.type || 'span';

    while ((match = keyFormat.exec(childChecked)) !== null) {

      const textBeforeMatch = childChecked.slice(lastIndex, match.index);
      if (textBeforeMatch) {
        parts.push({
          color: "",
          text: textBeforeMatch
        });
      }
      parts.push({
        color: match[1],
        text: match[2]
      });
      lastIndex = match.index + match[0].length;
    }

    const textAfterLastMatch = childChecked.slice(lastIndex);
    if (textAfterLastMatch) {
      parts.push({
        color: "",
        text: textAfterLastMatch
      });
    }

    const highlightedText = parts.map((part, index) => {
      if (part.color === "green") {
        return (
          <TAG key={index} className={css.greenText}>
            {part.text}
          </TAG>
        );
      } else if (part.color === "blue") {
        return (
          <TAG key={index} className={css.blueText}>
            {part.text}
          </TAG>
        );
      } else if (part.color === "white") {
        return (
          <TAG key={index} className={css.whiteText}>
            {part.text}
          </TAG>
        );
      } else if (part.color === "black") {
        return (
          <TAG key={index} className={css.blackText}>
            {part.text}
          </TAG>
        );
      } else if (part.color === "marketColor") {
        return (
          <TAG key={index} className={css.marketColorText}>
            {part.text}
          </TAG>
        );
      } else {

        return (
          <TAG key={index}>
            {part.text}
          </TAG>
        );
      }
    });

    return highlightedText;
  })

  otherProps.children = par;

  return <p className={classes} {...otherProps} ref={ref} />;
});

P.displayName = 'P';

P.defaultProps = {
  rootClassName: null,
  className: null,
  children: null,
};

P.propTypes = {
  rootClassName: string,
  className: string,
  children: node,
};
