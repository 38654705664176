import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockPageList.module.css';

const FieldMedia = props => {
    const { className, media, sizes, options } = props;
    const hasMediaField = hasDataInFields([media], options);
    return hasMediaField ? (
        <div className={classNames(className, css.media)}>
            <Field data={media} sizes={sizes} options={options} />
        </div>
    ) : null;
};

const BlockPageList = props => {
    const {
        blockId,
        className,
        rootClassName,
        mediaClassName,
        textClassName,
        ctaButtonClass,
        title,
        text,
        callToAction,
        media,
        responsiveImageSizes,
        options,
    } = props;
    const classes = classNames(rootClassName || css.root, className);
    const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
    return (
        <BlockContainer id={blockId} className={classes}>
            {console.log(callToAction)}
            {hasTextComponentFields ? (
                <div className={classNames(textClassName, css.text)}>
                    <Field data={callToAction} className={css.cta} options={options}>
                        <Field data={text} options={options} />
                        <Field data={title} options={options} className={css.title}/>
                    </Field>
                    <hr />
                </div>
            ) : null}
        </BlockContainer>
    );
};

const propTypeOption = shape({
    fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockPageList.defaultProps = {
    className: null,
    rootClassName: null,
    mediaClassName: null,
    textClassName: null,
    ctaButtonClass: null,
    title: null,
    text: null,
    callToAction: null,
    media: null,
    responsiveImageSizes: null,
    options: null,
};

BlockPageList.propTypes = {
    blockId: string.isRequired,
    className: string,
    rootClassName: string,
    mediaClassName: string,
    textClassName: string,
    ctaButtonClass: string,
    title: object,
    text: object,
    callToAction: object,
    media: object,
    responsiveImageSizes: string,
    options: propTypeOption,
};

export default BlockPageList;
