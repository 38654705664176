import React, { Component } from 'react';
import { array, arrayOf, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { parseSelectFilterOptions } from '../../util/search';
import { FieldCheckbox, FieldTextInput, FieldSelect } from '../../components';

import { FilterPopup, FilterPlain } from '../../components';
import css from './SelectMultipleFilter.module.css';

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = props => {
  const { id, className, textClassName, name, options, switchIcon } = props;
  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map((option, index) => {

          if (!option.filter) {
            return null;
          }

          const fieldId = `${id}.${option.key}`;
          if (option.typed) {

            if (option.type === 'select') {
              return (
                <li key={fieldId} className={classNames(css.item)}>
                  <FieldSelect
                    id={fieldId}
                    name={option.key}
                    label={option.label}
                    className={css.typedInput}
                    inputRootClass={css.inputRootClass}
                    fromCheckboxes={true}
                  >
                    <option value="" hidden>Type</option>
                    {option.options.map((option, index) => (
                      <option key={index} value={option.key}>
                        {option.label}
                      </option>
                    ))}
                  </FieldSelect>
                </li>
              )
            }


            return (
              <li key={fieldId} className={classNames(css.item)}>
                <FieldTextInput
                  id={fieldId}
                  name={option.key}
                  type={option.type || 'number'}
                  label={option.label}
                  placeholder={option.label}
                  className={css.typedInput}
                  inputRootClass={css.inputRootClass}
                  fromCheckboxes={true}
                />
              </li>
            );
          }

          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox 
                id={fieldId} 
                name={name} 
                label={option.label} 
                value={option.key} 
                textClassName={textClassName} 
                switchIcon={switchIcon}
                isFilter={true}
              />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

class SelectMultipleFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      searchMode,
      intl,
      showAsPopup,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);
    const queryParamName2 = getQueryParamName(queryParamNames[1]);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    const hasInitialValues2 = !!initialValues && !!initialValues[queryParamName2];
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const selectedOptions = hasInitialValues
      ? parseSelectFilterOptions(initialValues[queryParamName])
      : [];

    const selectedOptions2 = hasInitialValues2
      ? parseSelectFilterOptions(initialValues[queryParamName2])
      : [];

    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: selectedOptions.length }
        )
      : label;

    const labelForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilterPlainForm.labelSelected' },
          { labelText: label, count: selectedOptions.length }
        )
      : label;

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field

    const secondName = queryParamName2?.split('_')[1] || null;

    const namedInitialValues = secondName ? { [name]: selectedOptions, [secondName]: selectedOptions2 } : { [name]: selectedOptions };

    const handleSubmit = values => {
      // Get name for options that has type 'select', 'number' or 'text'
      const optionsWithTypes = options.filter(option => option.typed && option.filter);
      const optionsWithTypesNames = optionsWithTypes.map(option => option.key);
      const optionsWithTypesValues = values ? optionsWithTypesNames.map(name => values[name]) : [];
      // Format optionsWithTypesValues to correct format for query param
      // Example: { pub_key: 'has_all:a,b,c' }
      const formatOptionsWithTypesValues = optionsWithTypesValues.reduce((acc, option, index) => {
        const optionName = optionsWithTypesNames[index];
        const optionType = optionsWithTypes.find(option => option.key === optionName).type;
        const value = option ? `${option}` : null;
        acc[`pub_${optionName}`] = value;
        return acc;
      }, {});

      const usedValue = values ? values[name] : values;
      
      const extraValues = queryParamName2 ? {...format(usedValue, queryParamName2, searchMode)} : {};
      onSubmit({...format(usedValue, queryParamName, searchMode), ...extraValues, ...formatOptionsWithTypesValues});
    };
    
    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroup}
          textClassName={css.text}
          name={name}
          id={`${id}-checkbox-group`}
          options={options}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroupPlain}
          textClassName={css.text}
          name={name}
          id={`${id}-checkbox-group`}
          options={options}
          switchIcon={true}
        />
      </FilterPlain>
    );
  }
}

SelectMultipleFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectMultipleFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SelectMultipleFilter);
