/*
US and Canadian postal codes: \d{5}(?:-\d{4})
New Zealand postal codes: [A-Z]\d[A-Z]\s\d[A-Z]\d
South African postal codes: [A-Z]{1,2}\d{1,4}
UAE postal codes: [0-9]{4}[A-Z]{2}
Portuguese postal codes: \d{4}-\d{3}
South African postal codes: \d{4}
*/
export const removePostalCode = (address) => {
  const regex = /\d{5}(?:-\d{4})?|([A-Z]\d[A-Z]\s\d[A-Z]\d)|([A-Z]{1,2}\d{1,4})|([0-9]{4}[A-Z]{2})|([0-9]{1,2}[A-Z]{1,2}\d{1,2})|(\d{4}-\d{3})|\d{4}/g;
  return address.replace(regex, '');
}

export const shortenAddress = (address) => {
    // Remove whitespaces before a comma
    const addressWithoutWhitespaces = address.replace(/\s*,\s*/g, ',');
  
    // Split the address by commas
    const addressArray = addressWithoutWhitespaces.split(',');
  
    // Shorten the address to the last 3 elements
    const shortenedAddress = addressArray.slice(-3).join(', ');
  
    return shortenedAddress;
  }

  export const removePostalCodeAndShortenAddress = (address) => {
    // Remove the postal code
    const addressWithoutPostalCode = removePostalCode(address);
  
    // Shorten the address to the last 3 elements
    const shortenedAddress = shortenAddress(addressWithoutPostalCode);
  
    return shortenedAddress;
  }