import React, { useEffect } from 'react';
import Field, { hasDataInFields } from '../../containers/PageBuilder/Field'

import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import BlockContainer from '../../containers/PageBuilder/BlockBuilder/BlockContainer/BlockContainer';
// import field, { hasDataInFields } from '../../Field';

import css from './CarouselFirstBlock.module.css'

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};
import blue from '../../assets/Community/blue.svg'
import white from '../../assets/Community/white.svg'

const CarouselFirstBlock = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  useEffect(() => {
    // Obtener todos los elementos con la clase '.slick-track'
    const slickTracks = document.querySelectorAll('.slick-track');

    // Iterar sobre cada elemento y eliminar los atributos 'style' que contengan 'translate3d' y 'transition'
    slickTracks.forEach((slickTrack) => {
      const styleAttr = slickTrack.getAttribute('style');
      if (styleAttr && (styleAttr.includes('translate3d') || styleAttr.includes('transition'))) {
        slickTrack.removeAttribute('style');
      }
    });
  }, []);

  return (
    <BlockContainer id={blockId} className={classNames(classes, css.firstBlock, blockId)}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      <div className={css.blockQuotes}>
        { blockId === 'third-block' ||
        blockId === 'second-carousel-third-block' ||
        blockId === 'third-carousel-third-block' ?
        <img src={white} id={`${blockId}-img`}/>  :
        <img src={blue} id={`${blockId}-img`}/>
        }
      </div>
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} className={css.title}/>
          <Field data={text} options={options} className={css.text}/>
          <Field data={callToAction} className={ctaButtonClass} options={options} />
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

CarouselFirstBlock.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

CarouselFirstBlock.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default CarouselFirstBlock;
