import React from 'react'
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import css from './SubtitlePage.module.css';
import classNames from 'classnames';
import Field, { hasDataInFields } from '../../containers/PageBuilder/Field';
import BlockBuilder from '../../containers/PageBuilder/BlockBuilder';

import img from '../../assets/LandingPage/whyHome.jpg'
import {
  NamedLink
} from '../';

const SubtitlePage = props => {

  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.imageContainer}>
        <img src={img} className={css.image} />
      </div>
      <div className={css.content}>
          <Field data={title} className={classNames(css.whyHomeBaseTitle)} options={fieldOptions} />
          <Field data={description} className={classNames(css.whyHomeBaseDescription)} options={fieldOptions} />
        <NamedLink name="SearchPage" className={css.customButtonElement}>
          Book now
        </NamedLink>
      </div>
    </div>
  )
}

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SubtitlePage.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 2,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SubtitlePage.propTypes = {
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SubtitlePage;
