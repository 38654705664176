/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, FieldSelect, FieldTextInput, ValidationError } from '../../components';

import css from './FieldCheckboxGroup.module.css';
import { composeValidators, required } from '../../util/validators';

const FieldCheckboxRenderer = props => {
  const { className, rootClassName, label, twoColumns, id, fields, options, meta, switchIcon, values } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

  const namedObject = values ? values[id] : [];

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      <ul className={listClasses}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;

          if (option.typed) {
            const isDependantAndInactive = !option.dependant ? false : !namedObject ? true : namedObject.includes(option.dependant) ? false : true;

            if (option.type === 'select') {
              return (
                <li key={fieldId} className={classNames(css.item, {
                  [css.itemNotSelected]: isDependantAndInactive
                })}>
                  <FieldSelect
                    id={fieldId}
                    name={option.key}
                    label={option.label}
                    className={css.typedInput}
                    inputRootClass={css.inputRootClass}
                    successClassName={css.selectSuccess}
                    fromCheckboxes={true}
                    required={true}
                    validate={isDependantAndInactive ? false : composeValidators(required('Required'))}
                  >
                    <option value="" hidden>Type</option>
                    {option.options.map((option, index) => (
                      <option key={index} value={option.key}>
                        {option.label}
                      </option>
                    ))}
                  </FieldSelect>
                </li>
              )
            }


            return (
              <li key={fieldId} className={classNames(css.item, {
                [css.itemNotSelected]: isDependantAndInactive
              })}>
                <FieldTextInput
                  id={fieldId}
                  name={option.key}
                  type={option.type || 'number'}
                  label={option.label}
                  placeholder={option.label}
                  className={css.typedInput}
                  inputRootClass={css.inputRootClass}
                  fromCheckboxes={true}
                  validate={isDependantAndInactive ? false : composeValidators(required('Required'))}
                  required={true}
                />
              </li>
            );
          }

          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox
                id={fieldId}
                name={fields.name}
                label={option.label}
                value={option.key}
                switchIcon={switchIcon}
              />
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
};

const FieldCheckboxGroup = props => <FieldArray component={FieldCheckboxRenderer} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroup.propTypes = {
  name: string.isRequired,
};

export default FieldCheckboxGroup;
