/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    mobile: false,
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  }, 
  {
    id: 'type',
    label: 'Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    mobile: false,
    queryParamNames: ['pub_type'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'house', label: 'House' },
        { key: 'apartment', label: 'Apartment' },
      ],
    }
  },
  {
    id: 'dates',
    label: 'Dates',
    filterLabel: 'Pick a date range',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    mobile: true,
    order: 1,
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  /*{
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    mobile: false,
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },*/
  {
    id: 'address',
    label: 'Search a location',
    filterLabel: 'Search a location',
    type: 'AddressFilter',
    group: 'secondary',
    mobile: true,
    order: 2,
    queryParamNames: ['address', 'bounds'],
  },
  {
    id: 'category',
    label: 'Category',
    filterLabel: 'Select a location type',
    type: 'SelectLocationTypeFilter',
    group: 'secondary',
    mobile: true,
    order: 3,
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'mountain', label: 'Mountain' },
        { key: 'beach', label: 'Beach' },
        { key: 'countryside', label: 'Countryside' },
        { key: 'city', label: 'City' },
      ],
    },
  },
  {
    id: 'workspace',
    label: 'Workspace',
    filterLabel: 'Workspace amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    mobile: true,
    order: 4,
    queryParamNames: ['pub_workspace', 'pub_noworkstations'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      extraOptions: [
        { key: '1', label: '1' },
        { key: '2', label: '2' },
        { key: '3', label: '3' },
        { key: '4', label: '4' },
        { key: '5', label: '5' },
        { key: '6', label: '6 or more' },
      ],
      options: [
        {
          key: 'noworkstations',
          label: "No. of workstations",
          placeholder: 'How many',
          typed: true,
          type: 'select',
          options: [
            { key: '1', label: '1' },
            { key: '2', label: '2' },
            { key: '3', label: '3' },
            { key: '4', label: '4' },
            { key: '5', label: '5' },
            { key: '6', label: '6 or more' },
          ],
          filter: true,
        },
        {
          key: 'monitor',
          label: 'Monitors',
          placeholder: 'How many',
          typed: true,
          type: 'select',
          options: [
            { key: '1', label: '1' },
            { key: '2', label: '2' },
            { key: '3', label: '3' },
            { key: '4', label: '4' },
            { key: '5', label: '5' },
            { key: '6', label: '6 or more' },
          ],
          filter: false,
        },
        {
          key: 'keyboardandmouse',
          label: 'Keyboard & mouse',
          placeholder: 'How many sets',
          typed: true,
          type: 'select',
          options: [
            { key: '1', label: '1' },
            { key: '2', label: '2' },
            { key: '3', label: '3' },
            { key: '4', label: '4' },
            { key: '5', label: '5' },
            { key: '6', label: '6 or more' },
          ],
          filter: false,
        },
        {
          key: 'laptop-stand',
          label: 'Laptop stand',
          filter: true,
        },
        {
          key: 'Standing-desk',
          label: 'Standing desk',
          filter: true,
        },
        {
          key: 'window-view',
          label: 'Window view',
          filter: true,
        },
        {
          key: 'ergonomic-chair',
          label: 'Ergonomic chair',
          filter: true,
        },
        {
          key: 'herman-miller-chair',
          label: 'Herman Miller chair',
          filter: false,
        },
        {
          key: 'international-adaptor',
          label: 'International adaptor',
          filter: true,
        },
      ],
    },
  },
  {
    id: 'living',
    label: 'Living',
    filterLabel: 'Living amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    mobile: true,
    order: 5,
    queryParamNames: ['pub_living', 'pub_typeofcoffee'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      extraOptions: [
        { key: '1', label: '1' },
        { key: '2', label: '2' },
        { key: '3', label: '3' },
        { key: '4', label: '4' },
        { key: '5', label: '5' },
        { key: '6', label: '6 or more' },
      ],
      options: [
        {
          key: 'typeofcoffee',
          label: 'Type of coffee',
          typed: true,
          type: 'select',
          options: [
            { key: 'drip', label: 'Drip' },
            { key: 'french-press', label: 'French press' },
            { key: 'espresso', label: 'Espresso' },
            { key: 'keurig', label: 'Keurig' },
            { key: 'nespresso', label: 'Nespresso' },
          ],
          filter: true,
        },
        {
          key: 'kitchen',
          label: 'Kitchen essentials',
          filter: false,
        },
        {
          key: "microwave",
          label: 'Microwave',
          filter: false,
        },
        {
          key: 'washer',
          label: 'Washer',
          filter: true,
        },
        {
          key: 'dryer',
          label: 'Dryer',
          filter: true,
        },
        {
          key: 'soap-shampoo',
          label: 'Soap/Shampoo',
          filter: false,
        },
        {
          key: 'iron',
          label: 'Iron',
          filter: false,
        },
        {
          key: 'ac',
          label: 'AC',
          filter: false,
        },
        {
          key: 'tv',
          label: 'TV',
          filter: false,
        },
        {
          key: 'balcony',
          label: 'Balcony',
          filter: true,
        },
        {
          key: 'heating',
          label: 'Heating',
          filter: false,
        },
        {
          key: 'parking',
          label: 'Parking available',
          filter: true,
        },
        {
          key: 'exercise-equipment',
          label: 'Exercise equipment',
          filter: false,
        },
        {
          key: 'carbon-monoxide-monitor',
          label: 'Carbon monoxide monitor',
          filter: false,
        },
        {
          key: 'smoke-alarm',
          label: 'Smoke alarm',
          filter: false,
        },
        {
          key: 'ev-charging',
          label: 'EV charging',
          filter: false,
        },
        {
          key: 'smart-lock',
          label: 'Smart lock',
          filter: false,
        },
        {
          key: 'family-friendly',
          label: 'Family friendly',
          filter: false,
        },
        {
          key: 'pet-friendly',
          label: 'Pet friendly',
          filter: false,
        },
      ],
    },
  },
  {
    id: 'noOfGuests',
    label: 'Number of guests',
    filterLabel: 'Number of guests',
    type: 'GuestNumberFilter',
    group: 'secondary',
    mobile: true,
    order: 6,
    queryParamNames: ['pub_noOfGuests'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '1', label: '1' },
        { key: '2', label: '2' },
        { key: '3', label: '3' },
        { key: '4', label: '4' },
        { key: '5', label: '5' },
        { key: '6,', label: '6 or more' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
