import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { EditListingPublishForm } from '../../forms';

import css from './EditListingPublishPanel.module.css';

class EditListingPublishPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      className,
      rootClassName,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      backButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      params,
      redirectAfterPrevTab,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const panelTitle =  (
      <FormattedMessage id="EditListingPublishPanel.title" />
    );

    return (
      <div className={classes}>
        <div className={css.header}>
          <h1 className={css.title}>{panelTitle}</h1>
          <p className={css.number}>
            10
          </p>
        </div>
        <EditListingPublishForm
          className={css.form}
          initialValues={{}}
          onSubmit={values => {
            
            onSubmit(values);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          backButtonText={backButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          params={params}
          redirectAfterPrevTab={redirectAfterPrevTab}
          {...rest}
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingPublishPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPublishPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPublishPanel;
