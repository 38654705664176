import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import { sendContactEmail } from '../../../../util/api';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockContactForm.module.css';
import { ContactUsForm } from '../../../../forms';
import { SecondaryButton, IconCircleCheck, Modal } from '../../../../components';

const FieldMedia = props => {
    const { className, media, sizes, options } = props;
    const hasMediaField = hasDataInFields([media], options);
    return hasMediaField ? (
        <div className={classNames(className, css.media)}>
            <Field data={media} sizes={sizes} options={options} />
        </div>
    ) : null;
};

const BlockContactForm = props => {
    const {
        blockId,
        className,
        rootClassName,
    } = props;
    const classes = classNames(rootClassName || css.root, className);
    const [emailSuccess, setEmailSuccess] = React.useState(false);
    const [emailFail, setEmailFail] = React.useState(false);

    const handleSubmit = (values, form) => {
        const { name, lastName, email, message } = values;

        const body = {
            name: name + ' ' + lastName,
            email,
            message,
        }

        sendContactEmail(body).then((res) => {
            if (res.success) {
                setEmailSuccess(true);
                form.reset();
                form.resetFieldState('name');
                form.resetFieldState('lastName');
                form.resetFieldState('email');
                form.resetFieldState('message');
            } else {
                setEmailFail(true);
            }

        }).catch((err) => {
            console.log(err);
            setEmailFail(true);
        });
    }

    return (
        <BlockContainer id={blockId} className={classes}>
            <ContactUsForm
                id="contactus"
                className={classNames(css.form)}
                onSubmit={handleSubmit}
                emailFail={emailFail}
            />
            <Modal
                id="emailSuccess"
                isOpen={emailSuccess}
                onClose={() => setEmailSuccess(false)}
                onManageDisableScrolling={() => { }}
                containerClassName={css.modal}
                closeButtonMessage="Close"
                usePortal
            >
                <div className={css.modalContent}>
                    <IconCircleCheck className={css.modalIcon} />
                    <h2 className={css.modalTitle}>Thank you for contacting us.</h2>
                    <p className={css.modalMessage}>Our team will get in touch with you shortly.</p>
                    <SecondaryButton
                        className={css.modalButton}
                        onClick={() => setEmailSuccess(false)}
                    >
                        Close
                    </SecondaryButton>
                </div>
            </Modal>
        </BlockContainer>
    );
};

const propTypeOption = shape({
    fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockContactForm.defaultProps = {
    className: null,
    rootClassName: null,
    mediaClassName: null,
    textClassName: null,
    ctaButtonClass: null,
    title: null,
    text: null,
    callToAction: null,
    media: null,
    responsiveImageSizes: null,
    options: null,
};

BlockContactForm.propTypes = {
    blockId: string.isRequired,
    className: string,
    rootClassName: string,
    mediaClassName: string,
    textClassName: string,
    ctaButtonClass: string,
    title: object,
    text: object,
    callToAction: object,
    media: object,
    responsiveImageSizes: string,
    options: propTypeOption,
};

export default BlockContactForm;
