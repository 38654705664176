import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, PrimaryButton as Button, FieldTextInput, NamedLink } from '../../components';

import check from '../../assets/CreationListingProcess/check.png';
import css from './EditListingPublishForm.module.css';

export const EditListingPublishFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        backButtonText,
        updated,
        updateInProgress,
        fetchErrors,
        params,
        redirectAfterPrevTab,
        isNewListingFlow,
        backToListing,
        backToListingText,
      } = formRenderProps;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPublishForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPublishForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <div className={css.label}>
            <FormattedMessage id="EditListingPublishForm.title" />
          </div>

          <div className={css.sections}>
            <div className={css.section}>
              <NamedLink name="EditListingPage" params={{ ...params, tab: 'description' }} className={css.link}>
              <img src={check} alt="check" className={css.check} />
                <FormattedMessage id="EditListingPublishForm.tabLabelDescription" />
              </NamedLink>
            </div>
            <div className={css.section}>
              <NamedLink name="EditListingPage" params={{ ...params, tab: 'availability' }} className={css.link}>
              <img src={check} alt="check" className={css.check} />
                <FormattedMessage id="EditListingPublishForm.tabLabelAvailability" />
              </NamedLink>
            </div>
            <div className={css.section}>
              <NamedLink name="EditListingPage" params={{ ...params, tab: 'wifiSpeed' }} className={css.link}>
              <img src={check} alt="check" className={css.check} />
                <FormattedMessage id="EditListingPublishForm.tabLabelWifiSpeed" />
              </NamedLink>
            </div>
            <div className={css.section}>
              <NamedLink name="EditListingPage" params={{ ...params, tab: 'pricing' }} className={css.link}>
              <img src={check} alt="check" className={css.check} />
                <FormattedMessage id="EditListingPublishForm.tabLabelPricing" />
              </NamedLink>
            </div>
            <div className={css.section}>
              <NamedLink name="EditListingPage" params={{ ...params, tab: 'workspace' }} className={css.link}>
              <img src={check} alt="check" className={css.check} />
                <FormattedMessage id="EditListingPublishForm.tabLabelWorkspace" />
              </NamedLink>
            </div>
            <div className={css.section}>
              <NamedLink name="EditListingPage" params={{ ...params, tab: 'policy' }} className={css.link}>
              <img src={check} alt="check" className={css.check} />
                <FormattedMessage id="EditListingPublishForm.tabLabelAdditionalInfo" />
              </NamedLink>
            </div>
            <div className={css.section}>
              <NamedLink name="EditListingPage" params={{ ...params, tab: 'living' }} className={css.link}>
              <img src={check} alt="check" className={css.check} />
                <FormattedMessage id="EditListingPublishForm.tabLabelFeatures" />
              </NamedLink>
            </div>
            <div className={css.section}>
              <NamedLink name="EditListingPage" params={{ ...params, tab: 'location' }} className={css.link}>
              <img src={check} alt="check" className={css.check} />
                <FormattedMessage id="EditListingPublishForm.tabLabelPolicy" />
              </NamedLink>
            </div>
            <div className={css.section}>
              <NamedLink name="EditListingPage" params={{ ...params, tab: 'photos' }} className={css.link}>
                <img src={check} alt="check" className={css.check} />
                <FormattedMessage id="EditListingPublishForm.tabLabelPhotos" />
              </NamedLink>
            </div>
          </div>

          <div className={css.buttonWrapper}>
            <Button
              className={css.backButton}
              type="button"
              inProgress={submitInProgress}
              onClick={redirectAfterPrevTab}
            >
              {backButtonText}
            </Button>

            <div className={css.buttonWrapper}>
              {!isNewListingFlow ? (
                    <Button
                      className={css.submitButton}
                      type="button"
                      inProgress={submitInProgress}
                      onClick={backToListing}
                    >
                      {backToListingText}
                    </Button>
                  ) : (
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      ready={submitReady}
                    >
                      {saveActionMsg}
                    </Button>
                  )}
              
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPublishFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingPublishFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPublishFormComponent);
