import React from 'react'
import css from './WorkingStation.module.css';
import classNames from 'classnames';
import homebaseFake1 from '../../assets/homebaseFake1.png'
import homebaseFake2 from '../../assets/homebaseFake2.png'
import homebaseFake3 from '../../assets/homebaseFake3.png'
import {
  NamedLink
} from '../';

export default function WorkingStation(props) {
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.ourWorkStation}>
        <div className={css.ourWorkStationTexts}>
          <h1 className={css.owsTitle}>
            Our Workstation
          </h1>
          <p className={css.owsText}>
            Homebase offers a home rental experience designed to inspire and foster productivity.
          </p>
        </div>
        <div className={css.owsCards}>
          <div className={css.owsCardsContainer}>
            <div className={css.secondaryCards}>
              <div className={css.owsCardOne}>
                <div className={css.owsCardImage}>
                  <img src={homebaseFake1} className={css.owsCardImages} />
                </div>
                <div className={css.owsCardFooter}>
                  <div className={css.owsCardFooterText}>
                    Professional Set Up
                  </div>
                </div>
              </div>
              <div className={css.owsCardTwo}>
                <img src={homebaseFake2} className={css.owsCardImages} />
                <div className={css.owsCardFooter}>
                  <div className={css.owsCardFooterText}>
                    Spectacular Views
                  </div>
                </div>
              </div>
              <div className={css.owsCardTwo}>
                <img src={homebaseFake3} className={css.owsCardImages} />
                <div className={css.owsCardFooter}>
                  <div className={css.owsCardFooterText}>
                    Unlimited Coffee
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={css.owsLearnMore}>
          <NamedLink name="CMSPage" params={{ pageId: "why-homebase" }} className={css.owsLearnMoreButton}>
            Learn More
          </NamedLink>
        </div>
      </div>
    </div>
  )
}
