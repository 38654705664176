import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';

import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaLinkedIn,
  IconSocialMediaYoutube,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteLinkedInPage, siteYoutubePage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const linkedInLink = siteLinkedInPage ? (
    <ExternalLink
      key="LinkToLinkedIn"
      href={siteLinkedInPage}
      className={css.icon}
    //   title={goToLinkedIn}
    >
      <IconSocialMediaLinkedIn />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteYoutubePage ? (
    <ExternalLink
      key="linkToYoutube"
      href={siteYoutubePage}
      className={css.icon}
    //   title={goToYoutube}
    >
      <IconSocialMediaYoutube />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : 'ho';
  return [linkedInLink, youtubeLink, twitterLink, instragramLink, fbLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="custom_white" className={css.logo_white} />
                <div className={css.logo_white}>

                </div>
              </NamedLink>
              <div className={css.organizationInfo}>
                <div className={css.organizationCopyright}>
                  <div className={css.organizationSocials}>
                    <div className={css.someLinks}>{socialMediaLinks}</div>
                  </div>
                </div>
                <NamedLink name="LandingPage" className={css.copyrightLink}>
                  <FormattedMessage id="Footer.copyright" />
                </NamedLink>
              </div>
            </div>
            <div className={css.extraLinks}>
              <h3>
                <FormattedMessage id="Footer.contactUsLabel" />
              </h3>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li className={css.extra}>
                    <FormattedMessage id="Footer.contactLabel" />
                  </li>
                  <li className={css.contact}>
                    <NamedLink name="CMSPage" params={{ pageId: 'contact-us' }} className={css.contact}>
                      <FormattedMessage id="Footer.toContactPage" />
                    </NamedLink>
                  </li>
                  <li className={css.extra}>
                    <FormattedMessage id="Footer.contactEmailLabel" />
                  </li>
                  <li>
                    <a className={css.mailto} href="mailto:info@bookhomebase.com">
                      <FormattedMessage id="Footer.contactEmail" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={css.infoLinks}>
              <h3><FormattedMessage id={"Footer.menuLabel"} /></h3>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="LandingPage" className={css.link}>
                    <FormattedMessage id="Footer.homePage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'why-homebase' }} className={css.link}>
                    <FormattedMessage id="Footer.whyHomebasePage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'investors' }} className={css.link}>
                    <FormattedMessage id="Footer.investorPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'house-rules' }} className={css.link}>
                    <FormattedMessage id="Footer.houseRulesPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <h3>&nbsp;</h3>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'become-a-host' }} className={css.link}>
                    <FormattedMessage id="Footer.becomeAHostPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'blogs' }} className={css.link}>
                    <FormattedMessage id="Footer.blogPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'terms-of-service' }} className={css.link}>
                    <FormattedMessage id="Footer.termsOfUse" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'privacy-policy' }} className={css.link}>
                    <FormattedMessage id="Footer.privacyPolicy" />
                  </NamedLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={css.mobileWrapper}>
        <div className={css.organization} id="organization">
          <NamedLink name="LandingPage" className={css.logoLink}>
            <Logo format="custom_white" className={css.logo_white} />
            <div className={css.logo_white}>

            </div>
          </NamedLink>
          <div className={css.organizationInfo}>
            <div className={css.organizationCopyright}>
              <div className={css.organizationSocials}>
                <div className={css.someLinks}>{socialMediaLinks}</div>
              </div>
            </div>
          </div>
        </div>


        <div className={css.mobileLinks}>
          <h3><FormattedMessage id={"Footer.menuLabel"} /></h3>
          <ul className={css.list}>
            <li className={css.listItem}>
              <NamedLink name="NewListingPage" className={css.link}>
                <FormattedMessage id="Footer.homePage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="CMSPage" params={{ pageId: 'contact-us' }} className={css.link}>
                <FormattedMessage id="Footer.toContactPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="CMSPage" params={{ pageId: 'why-homebase' }} className={css.link}>
                <FormattedMessage id="Footer.whyHomebasePage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="CMSPage" params={{ pageId: 'become-a-host' }} className={css.link}>
                <FormattedMessage id="Footer.becomeAHostPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="CMSPage" params={{ pageId: 'investors' }} className={css.link}>
                <FormattedMessage id="Footer.investorPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="CMSPage" params={{ pageId: 'blogs' }} className={css.link}>
                <FormattedMessage id="Footer.blogPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="CMSPage" params={{ pageId: 'house-rules' }} className={css.link}>
                <FormattedMessage id="Footer.houseRulesPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="CMSPage" params={{ pageId: 'terms-of-service' }} className={css.link}>
                <FormattedMessage id="Footer.termsOfUse" />
              </NamedLink>
            </li>
          </ul>
        </div>
        <div className={css.extraLinks}>
          <h3>
            <FormattedMessage id="Footer.contactUsLabel" />
          </h3>
          <div className={css.legalMatters}>
            <ul className={css.tosAndPrivacy}>

              <li className={css.listItem}>
                <FormattedMessage id="Footer.contactLabel" />
              </li>
              <li className={css.listItem}>
                <NamedLink name="CMSPage" params={{ pageId: 'contact-us' }} className={css.linkContact}>
                  <FormattedMessage id="Footer.toContactPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <FormattedMessage id="Footer.contactEmailLabel" />
              </li>
              <li>
                <a className={css.mailto} href="mailto:info@bookhomebase.com">
                  <FormattedMessage id="Footer.contactEmail" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <NamedLink name="LandingPage" className={css.copyrightLink}>
          <FormattedMessage id="Footer.copyright" />
        </NamedLink>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
