import React, { useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { EditListingWifiSpeedForm } from '../../forms';
import { Modal } from '../../components'

import css from './EditListingWifiSpeedPanel.module.css';


const EditListingWifiSpeedPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling,
    backButtonText,
    redirectAfterPrevTab,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const [modal, setModal] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);

  const panelTitle = (
    <FormattedMessage
      id="EditListingWifiSpeedPanel.title"
    />
  )

  const handleLoad = () => {
    if (modalFailed) {
      setModalFailed(false)
    }
  }

  const handleError = () => {
    if (!modalFailed) {
      setModalFailed(true)
    }
  }

  useEffect(() => {
    if (modalFailed) {
      // Open site in new tab
      window?.open('https://fast.com/', '_blank');
    }
  }, [modal])

  return (
    <div className={classes}>
      <div className={css.header}>
        <h1 className={css.title}>{panelTitle}</h1>
        <p className={css.number}>
          02
        </p>
      </div>
      <EditListingWifiSpeedForm
        className={css.form}
        initialValues={{ 
          downloadSpeed: publicData.downloadSpeed,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { downloadSpeed } = values;
          const updateValues = {
            publicData: { 
              downloadSpeed: downloadSpeed || "",
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        modal={modal}
        setModal={setModal}
        backButtonText={backButtonText}
        redirectAfterPrevTab={redirectAfterPrevTab}
        {...rest}
      />

      <Modal
        id="WifiSpeedModal"
        isOpen={modal}
        onClose={() => setModal(!modal)}
        onManageDisableScrolling={onManageDisableScrolling}
        className={css.modal}
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
      >
        <iframe src="https://fast.com/" width="100%" height="100%" onLoad={handleLoad} onError={handleError}></iframe>
      </Modal>
    </div>
  );
};

EditListingWifiSpeedPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingWifiSpeedPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingWifiSpeedPanel;
