import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import routeConfiguration from '../../../../routeConfiguration.js';
import { matchPathname } from '../../../../util/routes.js';

import { NamedLink, ExternalLink } from '../../../../components/index.js';
import css from './Link.module.css';

export const Link = React.forwardRef((props, ref) => {
  const location = useLocation();
  const { className, rootClassName, href, title, children } = props;
  const classes = classNames(rootClassName || css.link, className);
  const titleMaybe = title ? { title } : {};

  // Markdown parser (rehype-sanitize) might return undefined href
  if (!href || !children) {
    return null;
  }

  let par = children;

  if (typeof children !== 'string') {
    par = children.map(child => {
      // Check for matches of the form {{color}}text{{color}}
      const keyFormat = /\{\{(green|blue|white|black|marketColor)\}\}([^]*?)\{\{\1\}\}/g;
      const parts = [];
      let match;
  
      let lastIndex = 0;
  
      if (typeof child !== 'string') {
        return child;
      }
  
      const childChecked = typeof child === 'string' ? child : child.props.children;
  
      const TAG = typeof child === 'string' ? 'span' : child.type || 'span';
  
      while ((match = keyFormat.exec(childChecked)) !== null) {
  
        const textBeforeMatch = childChecked.slice(lastIndex, match.index);
        if (textBeforeMatch) {
          parts.push({
            color: "",
            text: textBeforeMatch
          });
        }
        parts.push({
          color: match[1],
          text: match[2]
        });
        lastIndex = match.index + match[0].length;
      }
  
      const textAfterLastMatch = childChecked.slice(lastIndex);
      if (textAfterLastMatch) {
        parts.push({
          color: "",
          text: textAfterLastMatch
        });
      }

      const highlightedText = parts.map((part, index) => {
        if (part.color === "green") {
          return (
            <TAG key={index} className={css.greenText}>
              {part.text}
            </TAG>
          );
        } else if (part.color === "blue") {
          return (
            <TAG key={index} className={css.blueText}>
              {part.text}
            </TAG>
          );
        } else if (part.color === "white") {
          return (
            <TAG key={index} className={css.whiteText}>
              {part.text}
            </TAG>
          );
        } else if (part.color === "black") {
          return (
            <TAG key={index} className={css.blackText}>
              {part.text}
            </TAG>
          );
        } else if (part.color === "marketColor") {
          return (
            <TAG key={index} className={css.marketColorText}>
              {part.text}
            </TAG>
          );
        } else {
          return (
            <TAG key={index}>
              {part.text}
            </TAG>
          );
        }
      });
  
      return highlightedText;
    }) 
  }

  const linkProps = { className: classes, href, children: par, ...titleMaybe };

  if (href.charAt(0) === '/') {
    // Internal link
    const testURL = new URL('http://my.marketplace.com' + href);
    const matchedRoutes = matchPathname(testURL.pathname, routeConfiguration());
    if (matchedRoutes.length > 0) {
      const found = matchedRoutes[0];
      const to = { search: testURL.search, hash: testURL.hash };
      return (
        <NamedLink name={found.route.name} params={found.params} to={to} {...linkProps} ref={ref} />
      );
    }
  }

  if (href.charAt(0) === '#') {
    if (typeof window !== 'undefined') {
      const hash = href;
      let testURL = new URL(
        `http://my.marketplace.com${location.pathname}${location.hash}${location.search}`
      );
      testURL.hash = hash;
      const matchedRoutes = matchPathname(testURL.pathname, routeConfiguration());
      if (matchedRoutes.length > 0) {
        const found = matchedRoutes[0];
        const to = { search: testURL.search, hash: testURL.hash };
        return (
          <NamedLink
            name={found.route.name}
            params={found.params}
            to={to}
            {...linkProps}
            ref={ref}
          />
        );
      }
    }
  }

  return <ExternalLink {...linkProps} ref={ref} />;
});

Link.displayName = 'Link';

Link.defaultProps = {
  rootClassName: null,
  className: null,
};

Link.propTypes = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
  href: string.isRequired,
};
