import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldSelect.module.css';

const FieldSelectComponent = props => {
  const { rootClassName, className, inputRootClass, successClassName, id, label, input, meta, children, fromCheckboxes, required, ...rest } = props;
  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const hasCustomSuccessStyles = successClassName && valid ? successClassName : null;

  const selectClasses = classNames(css.select, inputRootClass, hasCustomSuccessStyles, {
    [css.selectSuccess]: valid && !successClassName,
    [css.selectError]: hasError,
  });
  const selectProps = { className: selectClasses, id, ...input, ...rest };

  const classes = classNames(rootClassName || css.root, className);

  if (fromCheckboxes) {
    return (
      <div className={classes} style={{}}>
        {label ? <label htmlFor={id} className={css.label} style={{width: 'calc(50% - 24px)'}}>{label} {required && (<span className={css.labelGreen}>*</span>)}</label> : null}
        <div style={{display: 'flex', flexDirection: 'column', maxWidth: '50%'}}>
          <select {...selectProps}>{children}</select>
          <ValidationError fieldMeta={meta} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label} {required && (<span className={css.labelGreen}>*</span>)}</label> : null}
      <select {...selectProps}>{children}</select>
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
  children: null,
};

const { string, object, node } = PropTypes;

FieldSelectComponent.propTypes = {
  rootClassName: string,
  className: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldSelect = props => {
  return <Field component={FieldSelectComponent} {...props} />;
};

export default FieldSelect;
