import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators, autocompleteSearchRequired, autocompletePlaceSelected } from '../../util/validators';
import { Form, PrimaryButton as Button, FieldTextInput, FieldCheckbox, FieldRadioButton, LocationAutocompleteInputField, InlineTextButton, IconClose } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

import tooltipIcon from '../../assets/CreationListingProcess/tooltip.png'
import filledTooltipIcon from '../../assets/CreationListingProcess/filledTooltip.png'
import beach from '../../assets/CreationListingProcess/beach.jpg';
import mountain from '../../assets/CreationListingProcess/mountain.jpg';
import city from '../../assets/CreationListingProcess/city.jpg';
import countryside from '../../assets/CreationListingProcess/countryside.jpg';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 70;
const DESCRIPTION_MAX_LENGTH = 1000;
const TOTAL_STEPS = 3;
const identity = v => v;

const EditListingDescriptionFormComponent = props => {
  const [step, setStep] = useState(0);
  const [nextClicked, setNextClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [tooltip, setTooltip] = useState(false);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          typeOptions,
          backButtonText,
          panelTitle,
          backToListing,
          backToListingText,
          isNewListingFlow
        } = formRenderProps;

        const titleMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.title',
        });

        const titlePlaceholderMessage = intl.formatMessage({
          id: `EditListingDescriptionForm.${values?.category || 'title'}Placeholder`,
        });
        const titleRequiredMessage = intl.formatMessage({
          id: `EditListingDescriptionForm.titleRequired`,
        });

        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionPlaceholder',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionRequired',
        });
        const maxLength700Message = maxLength(maxLengthMessage, DESCRIPTION_MAX_LENGTH);

        const noOfGuestsPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.maxGuestNumberPlaceholder',
        });
        const noOfGuestsRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.maxGuestNumberRequired',
        });

        const noOfBedroomsPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.noOfBedroomPlaceholder',
        });
        const noOfBedroomsRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.noOfBedroomRequired',
        });

        const noOfBathroomsPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.noOfBathroomPlaceholder',
        });

        const noOfSquareFeetPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.squareFeetPlaceholder',
        });

        const noOfSquareFeetMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.squareFeetRequired',
        });

        const noOfBathroomsRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.noOfBathroomRequired',
        });

        const addressPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.addressPlaceholder',
        });
        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.addressNotRecognized',
        });

        const buildingPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.buildingPlaceholder',
        });

        const strPolicyNumberPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.strPolicyNumberPlaceholder',
        });

        const exclusiveLabelMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.exclusiveLabel',
        });


        const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const firstDisabled = step === 0 && !values.category;
        const secondDisabled = step === 1 && (!values.title || !values.description);

        return (
          <>
            <FormSpy
              subscription={{ errors: true }}
              onChange={({ errors }) => {
                setErrors(errors);
              }}
            />
            <div className={css.header}>
              <h1 className={css.title}>{panelTitle} <br className={css.break} /><span className={css.emphasis}>({step + 1} of {TOTAL_STEPS})</span></h1>
              <p className={css.number}>
                01
              </p>
            </div>
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}

              <div className={classNames(css.stepOne, {
                [css.active]: step === 0
              })}>
                <label className={css.label}>
                  <FormattedMessage id="EditListingDescriptionForm.categoryLabel" />
                </label>

                <div className={css.category}>
                  <label className={classNames(css.item, {
                    [css.checked]: values.category === 'beach'
                  })}>
                    <FieldRadioButton type="radio" name="category" id="beach" value="beach" className={css.radio} />
                    <img className={css.img} src={beach} alt="beach" />
                    <p className={css.itemLabel}>
                      <FormattedMessage id="EditListingDescriptionForm.categoryBeach" />
                    </p>
                  </label>
                  <label className={classNames(css.item, {
                    [css.checked]: values.category === 'mountain'
                  })}>
                    <FieldRadioButton type="radio" name="category" id="mountain" value="mountain" className={css.radio} />
                    <img className={css.img} src={mountain} alt="mountain" />
                    <p className={css.itemLabel}>
                      <FormattedMessage id="EditListingDescriptionForm.categoryMountain" />
                    </p>
                  </label>
                  <label className={classNames(css.item, {
                    [css.checked]: values.category === 'city'
                  })}>
                    <FieldRadioButton type="radio" name="category" id="city" value="city" className={css.radio} />
                    <img className={css.img} src={city} alt="city" />
                    <p className={css.itemLabel}>
                      <FormattedMessage id="EditListingDescriptionForm.categoryCity" />
                    </p>
                  </label>
                  <label className={classNames(css.item, {
                    [css.checked]: values.category === 'countryside'
                  })}>
                    <FieldRadioButton type="radio" name="category" id="countryside" value="countryside" className={css.radio} />
                    <img className={css.img} src={countryside} alt="countryside" />
                    <p className={css.itemLabel}>
                      <FormattedMessage id="EditListingDescriptionForm.categoryCountryside" />
                    </p>
                  </label>
                </div>
              </div>

              <div className={classNames(css.stepTwo, {
                [css.active]: step === 1
              })}>
                <label className={css.label}>
                  <FormattedMessage id="EditListingDescriptionForm.secondStepLabel" />
                </label>

                <FieldTextInput
                  id="title"
                  name="title"
                  className={css.title}
                  inputRootClass={css.titleInput}
                  type="textarea"
                  label={titleMessage}
                  placeholder={titlePlaceholderMessage}
                  maxLength={TITLE_MAX_LENGTH}
                  hasWordCount={"top"}
                  customShowError={nextClicked}
                  validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                  rows={2}
                  autoFocus
                  required={true}
                />

                <FieldTextInput
                  id="description"
                  name="description"
                  className={css.description}
                  inputRootClass={css.input}
                  type="textarea"
                  label={descriptionPlaceholderMessage}
                  placeholder={descriptionPlaceholderMessage}
                  maxLength={DESCRIPTION_MAX_LENGTH}
                  hasWordCount={"bottom"}
                  customShowError={nextClicked}
                  rows={3}
                  validate={composeValidators(required(descriptionRequiredMessage), maxLength700Message)}
                  required={true}
                />

                <FieldCheckbox
                  id="exclusive"
                  name="exclusive"
                  label={exclusiveLabelMessage}
                  className={css.checkbox}
                  switchIcon={true}
                />
              </div>

              <div className={classNames(css.stepThree, {
                [css.active]: step === 2
              })}>
                <label className={css.label}>
                  <FormattedMessage id="EditListingDescriptionForm.thirdStepLabel" />
                </label>

                <CustomCategorySelectFieldMaybe
                  id="type"
                  name="type"
                  categories={typeOptions}
                  intl={intl}
                />

                <div className={css.holder}>
                  <FieldTextInput
                    id="noOfGuests"
                    name="noOfGuests"
                    className={css.half}
                    inputRootClass={css.input}
                    type="number"
                    label={noOfGuestsPlaceholderMessage}
                    placeholder={noOfGuestsPlaceholderMessage}
                    validate={composeValidators(required(noOfGuestsRequiredMessage))}
                    required={true}
                  />

                  <FieldTextInput
                    id="noOfBedrooms"
                    name="noOfBedrooms"
                    className={css.half}
                    inputRootClass={css.input}
                    type="number"
                    label={noOfBedroomsPlaceholderMessage}
                    placeholder={noOfBedroomsPlaceholderMessage}
                    validate={composeValidators(required(noOfBedroomsRequiredMessage))}
                    required={true}
                  />

                  <FieldTextInput
                    id="noOfBathrooms"
                    name="noOfBathrooms"
                    className={css.half}
                    inputRootClass={css.input}
                    type="number"
                    label={noOfBathroomsPlaceholderMessage}
                    placeholder={noOfBathroomsPlaceholderMessage}
                    validate={composeValidators(required(noOfBathroomsRequiredMessage))}
                    required={true}
                  />

                  <FieldTextInput
                    id="squareFeet"
                    name="squareFeet"
                    className={css.half}
                    inputRootClass={css.input}
                    type="number"
                    label={noOfSquareFeetPlaceholderMessage}
                    placeholder={noOfSquareFeetPlaceholderMessage}
                    validate={composeValidators(required(noOfSquareFeetMessage))}
                    required={true}
                  />
                </div>

                <label className={css.label} style={{ marginBottom: 0 }}>
                  <FormattedMessage id="EditListingDescriptionForm.thirdStepLabelTwo" />
                </label>
                <label className={css.label}>
                  <FormattedMessage id="EditListingDescriptionForm.thirdStepLabelThree" />
                </label>

                <LocationAutocompleteInputField
                  className={css.locationAddress}
                  labelClassName={css.locationLabel}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  autoFocus
                  name="locationInput"
                  label={addressPlaceholderMessage}
                  placeholder={addressPlaceholderMessage}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={composeValidators(
                    autocompleteSearchRequired(addressRequiredMessage),
                    autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                  required={true}
                />
                <p className={css.tip}>
                  <FormattedMessage id="EditListingDescriptionForm.locationTip" />
                </p>

                <FieldTextInput
                  id="building"
                  name="building"
                  className={css.building}
                  inputRootClass={css.input}
                  type="text"
                  placeholder={buildingPlaceholderMessage}
                />
                <p className={css.tip}>
                  <FormattedMessage id="EditListingDescriptionForm.buildingTip" />
                </p>

                <FieldTextInput
                  id="strPolicyNumber"
                  name="strPolicyNumber"
                  className={css.building}
                  inputRootClass={css.input}
                  type="text"
                  placeholder={strPolicyNumberPlaceholderMessage}
                />
                <p className={css.tip}>
                  <FormattedMessage id="EditListingDescriptionForm.strPolicyNumberTip" />
                </p>

                <div className={css.tooltip}>
                  <InlineTextButton type="button" onClick={() => setTooltip(!tooltip)} className={css.tooltip}>
                    <FormattedMessage id="EditListingDescriptionForm.tooltip"
                      values={{ icon: <img src={tooltipIcon} alt="tooltip" /> }}
                    />
                  </InlineTextButton>

                  <div className={classNames(css.tooltipContainer, {
                    [css.tooltipContainerActive]: tooltip,
                  })}>
                    <div className={css.tooltipHeader}>
                      <FormattedMessage id="EditListingDescriptionForm.tooltip"
                        values={{ icon: <img className={css.icon} src={filledTooltipIcon} alt="tooltip" /> }}
                      />

                      <InlineTextButton type="button" onClick={() => setTooltip(!tooltip)} className={css.close}>
                        <IconClose />
                      </InlineTextButton>
                    </div>
                    <div className={css.tooltipContent}>
                      <p className={css.tooltipDescription}>
                        <FormattedMessage id="EditListingDescriptionForm.tooltipContentOne" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>


              <div className={css.buttonWrapper}>
                {step > 0 && (
                  <Button
                    className={css.backButton}
                    type="button"
                    inProgress={submitInProgress}
                    onClick={() => setStep(step - 1)}
                  >
                    {backButtonText}
                  </Button>
                )}
                <div className={css.buttonWrapper}>
                  {!isNewListingFlow && (
                    <Button
                      className={css.listingButton}
                      type="button"
                      inProgress={submitInProgress}
                      onClick={backToListing}
                    >
                      {backToListingText}
                    </Button>
                  )}
                  {step < TOTAL_STEPS - 1 && (
                    <Button
                      className={css.submitButton}
                      type="button"
                      inProgress={submitInProgress}
                      ready={submitReady}
                      onClick={() => {
                        setNextClicked(true);
                        if (step === 0) {
                          setStep(step + 1);
                          setNextClicked(false);
                        }

                        if (step === 1 && !errors.title && !errors.description) {
                          setStep(step + 1);
                          setNextClicked(false);
                        }
                      }}
                    >
                      {step > 1 ? saveActionMsg : 
                        <FormattedMessage id="EditListingWizard.saveNewDescription" />}
                    </Button>
                  )}

                  {step === TOTAL_STEPS - 1 && (
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={submitInProgress}
                      ready={submitReady}
                    >
                      {saveActionMsg}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </>
        );
      }}
    />
  )
};

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
