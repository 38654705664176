import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, PrimaryButton as Button, FieldTextInput, InlineTextButton, IconClose } from '../../components';

import tooltipIcon from '../../assets/CreationListingProcess/tooltip.png'
import filledTooltipIcon from '../../assets/CreationListingProcess/filledTooltip.png'
import iconOne from '../../assets/CreationListingProcess/95%.png'
import iconTwo from '../../assets/CreationListingProcess/rocket.png'
import stepOne from '../../assets/CreationListingProcess/connected_tv.png'
import stepTwo from '../../assets/CreationListingProcess/content_copy.png'
import stepThree from '../../assets/CreationListingProcess/explore.png'

import css from './EditListingWifiSpeedForm.module.css';

const EditListingWifiSpeedFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        modal,
        setModal,
        backButtonText,
        redirectAfterPrevTab,
        isNewListingFlow,
        backToListing,
        backToListingText,
      } = formRenderProps;

      const uploadPlaceholderMessage = intl.formatMessage({
        id: 'EditListingWifiSpeedForm.uploadPlaceholder',
      });

      const downloadPlaceholderMessage = intl.formatMessage({
        id: 'EditListingWifiSpeedForm.downloadPlaceholder',
      });
      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingWifiSpeedForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingWifiSpeedForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingWifiSpeedForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const [tooltip, setTooltip] = useState(false);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <div className={css.header}>
            <div className={css.label}>
              <FormattedMessage id="EditListingWifiSpeedForm.title" />
            </div>

            <div className={css.tooltip}>
              <InlineTextButton type="button" onClick={() => setTooltip(!tooltip)} className={css.tooltip}>
                <FormattedMessage id="EditListingWifiSpeedForm.tooltip"
                  values={{ icon: <img src={tooltipIcon} alt="tooltip" /> }}
                />
              </InlineTextButton>

              <div className={classNames(css.tooltipContainer, {
                [css.tooltipContainerActive]: tooltip,
              })}>
                <div className={css.tooltipHeader}>
                  <FormattedMessage id="EditListingWifiSpeedForm.tooltip"
                    values={{ icon: <img className={css.icon} src={filledTooltipIcon} alt="tooltip" /> }}
                  />

                  <InlineTextButton type="button" onClick={() => setTooltip(!tooltip)} className={css.close}>
                    <IconClose />
                  </InlineTextButton>
                </div>
                <div className={css.tooltipContent}>
                  <p className={css.tooltipDescription}>
                    <img className={css.toolTipIcon} src={iconOne} />
                    <FormattedMessage id="EditListingWifiSpeedForm.tooltipContentOne" />
                  </p>

                  <p className={css.tooltipDescription}>
                    <img className={css.toolTipIcon} src={iconTwo} />
                    <FormattedMessage id="EditListingWifiSpeedForm.tooltipContentTwo" />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p className={css.description}>
            <img src={stepOne} alt="step one" className={css.step} />
            <FormattedMessage id="EditListingWifiSpeedForm.tipOne" />
          </p>

          <p className={css.description}>
            <img src={stepTwo} alt="step two" className={css.step} />
            <FormattedMessage id="EditListingWifiSpeedForm.tipTwo"
              values={{
                link: (
                  <InlineTextButton type="button" onClick={() => { setModal(!modal) }} className={css.link}>
                    <FormattedMessage id="EditListingWifiSpeedForm.tipTwoLink" />
                  </InlineTextButton>
                ),
                em: <span className={css.emphasis}><FormattedMessage id="EditListingWifiSpeedForm.tipTwoLinkText" /></span>,
              }}
            />
          </p>

          <p className={css.description}>
            <img src={stepThree} alt="step three" className={css.step} />
            <FormattedMessage id="EditListingWifiSpeedForm.tipThree" />
          </p>

          <div className={css.holder}>
            <FieldTextInput
              id="downloadSpeed"
              name="downloadSpeed"
              className={css.half}
              inputRootClass={css.input}
              type="text"
              placeholder={downloadPlaceholderMessage}
            />
          </div>

          <div className={css.buttonWrapper}>
            <Button
              className={css.backButton}
              type="button"
              inProgress={submitInProgress}
              onClick={redirectAfterPrevTab}
            >
              {backButtonText}
            </Button>
            <div className={css.buttonWrapper}>
              {!isNewListingFlow && (
                <Button
                  className={css.listingButton}
                  type="button"
                  inProgress={submitInProgress}
                  onClick={backToListing}
                >
                  {backToListingText}
                </Button>
              )}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingWifiSpeedFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingWifiSpeedFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingWifiSpeedFormComponent);
