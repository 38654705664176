import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';

import css from './EditListingPhotosPanel.module.css';

class EditListingPhotosPanel extends Component {
  constructor(props){
    super(props);

    this.state = {
      reorderedImages: this.props.images,
      firstImage: this.props.listing.attributes.publicData?.workspaceImage || null,
      secondImage: this.props.listing.attributes.publicData?.windowViewImage || null,
      newOrder: false
    }
  }

  componentDidUpdate() {
    // If the length of the images array changes, we need to reorder the images
    const lengthChanged = this.props.images.length !== this.state.reorderedImages.length;

    // If the length of the images array changes, we need to reorder the images
    if (lengthChanged || this.state.newOrder) {

      const copyImages = [];

      this.props.images.forEach((image, index) => {
        if (image.id.uuid === this.state.firstImage || image.imageId?.uuid === this.state.firstImage) {
          copyImages.unshift(image) // Add to the beginning of the array if it's the first image
        }

        if (image.id.uuid === this.state.secondImage || image.imageId?.uuid === this.state.secondImage) {
          copyImages.splice(1, 0, image) // Add to the second position of the array if it's the second image
        }

        if (image.id.uuid !== this.state.firstImage && image.id.uuid !== this.state.secondImage && image.imageId?.uuid !== this.state.firstImage && image.imageId?.uuid !== this.state.secondImage) {
          copyImages.push(image) // Add to the end of the array if it's not the first or second image
        }
      })

      this.setState({ reorderedImages: copyImages, newOrder: false }) // Set the new order of the images
    }
  }

  shouldComponentUpdate(props) {
    return props.images.every(i => !!i.imageId?.uuid || !!i.id?.uuid);
  }

  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      listing,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
      backButtonText,
      redirectAfterPrevTab,
      ...rest
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    
    const onSortEnd = (newImages) => {
      this.setState({ reorderedImages: newImages })
    }    

    const panelTitle = (
      <FormattedMessage id="EditListingPhotosPanel.title" />
    );

    const handleRemoveImage = image => {
      if (image.uuid === this.state.firstImage) {
        this.setState({ firstImage: null })
      }

      if (image.uuid === this.state.secondImage) {
        this.setState({ secondImage: null })
      }

      onRemoveImage(image);
    }

    return (
      <div className={classes}>
        <div className={css.header}>
          <h1 className={css.title}>{panelTitle}</h1>
          <p className={css.number}>
            05
          </p>
        </div>
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ images }}
          images={this.state.reorderedImages}
          flexImages={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, addImage2, addImage3, ...updateValues } = values;

            if (!!this.state.reorderedImages) {

              const reorderedValues = {
                ...updateValues,
                images: this.state.reorderedImages,
                publicData: {
                  workspaceImage: this.state.firstImage,
                  windowViewImage: this.state.secondImage,
                }
              } 

              onSubmit(reorderedValues)
            } else {

              onSubmit({
                ...updateValues,
                publicData: {
                  workspaceImage: this.state.firstImage,
                  windowViewImage: this.state.secondImage,
                }
              });
            }
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={handleRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          backButtonText={backButtonText}
          redirectAfterPrevTab={redirectAfterPrevTab}
          onSortEnd={onSortEnd}
          firstImageId={this.state.firstImage}
          setFirstImageId={(image) => {
            this.setState({ firstImage: image, newOrder: true })
          }}
          secondImageId={this.state.secondImage}
          setSecondImageId={(image) => {
            this.setState({ secondImage: image, newOrder: true })
          }}
          setReorderedImages={(images) => this.setState({ reorderedImages: images })}
          {...rest}
        />
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
