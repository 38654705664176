import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import CarouselFirstBlock from "../CarouselFirstBlock/CarouselFirstBlock";
import css from "./SimpleSlider.module.css";

const SimpleSlider = (props) => {
  const [windowSize, setWindowSize] = useState(0);

  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    options,
    blockId,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    text,
    media,
    responsiveImageSizes,
  } = props;

  const getTitle = () => {
    if (typeof title?.content === "string") {
      return <h1 className={css.sectionTitle}>{title?.content}</h1>;
    }
    return null;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    if (typeof window !== "undefined") {
      if (windowSize === 0) {
        setWindowSize(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const createSliderSegments = (segmentSize) => {
    const segments = [];
    for (let i = 0; i < blocks.length; i += segmentSize) {
      const segment = blocks.slice(i, i + segmentSize);
      segments.push([...segment, ...segment]); // Repetir el segmento dos veces
    }
    return segments;
  };

  const renderCarousel = (segmentSize) => {
    const sliderSegments = createSliderSegments(segmentSize);
    return sliderSegments.map((segment, index) => (
      <div className={index === 1 ? "slider_rtl" : "slider"} key={index}>
        <div className="slide-track">
          {segment.map((block, innerIndex) => (
            <div className="slide" key={innerIndex}>
              <CarouselFirstBlock
                blockId={block.blockId}
                options={options}
                media={block.media}
                title={block.title}
                text={block.text}
              />
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const settings_mobile = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    waitForAnimate: false,
    variableWidth: true,
    centerMode: false,
    swipe: true,
    touchMove: true,
  };

  if (windowSize > 1024) {
    const segmentSize = Math.ceil(blocks.length / 3);
    const [segment1, segment2, segment3] = createSliderSegments(segmentSize);

    return (
      <div className="carousel">
        {getTitle()}
        {renderCarousel(segmentSize)}
      </div>
    );
  } else {
    return (
      <div className="carousel">
        {getTitle()}
        <Slider {...settings_mobile} className={css.some}>
          {blocks.map((block, index) => (
            <CarouselFirstBlock
              key={index}
              blockId={block.blockId}
              options={options}
              media={block.media}
              title={block.title}
              text={block.text}
            />
          ))}
        </Slider>
      </div>
    );
  }
};

export default SimpleSlider;
