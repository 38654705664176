import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { currentUserShowSuccess } from '../../ducks/user.duck';

export const ASSET_NAME = 'landing-page';


const { UUID } = sdkTypes;

const IMAGE_VARIANTS = {
    'fields.image': [
        // Listing images:
        'variants.landscape-crop',
        'variants.landscape-crop2x',
    ],
};

const firstId = process.env.REACT_APP_FEATURED_LISTING_ONE;
const secondId = process.env.REACT_APP_FEATURED_LISTING_TWO;
const thirdId = process.env.REACT_APP_FEATURED_LISTING_THREE;

// ================ Action types ================ //

export const FIRST_FEATURED_LISTING_REQUEST = 'app/LandingPage/FIRST_LISTING_REQUEST';
export const FIRST_FEATURED_LISTING_SUCCESS = 'app/LandingPage/FIRST_LISTING_SUCCESS';
export const FIRST_FEATURED_LISTING_ERROR = 'app/LandingPage/FIRST_LISTING_ERROR';

export const SECOND_FEATURED_LISTING_REQUEST = 'app/LandingPage/SECOND_LISTING_REQUEST';
export const SECOND_FEATURED_LISTING_SUCCESS = 'app/LandingPage/SECOND_LISTING_SUCCESS';
export const SECOND_FEATURED_LISTING_ERROR = 'app/LandingPage/SECOND_LISTING_ERROR';

export const THIRD_FEATURED_LISTING_REQUEST = 'app/LandingPage/THIRD_LISTING_REQUEST';
export const THIRD_FEATURED_LISTING_SUCCESS = 'app/LandingPage/THIRD_LISTING_SUCCESS';
export const THIRD_FEATURED_LISTING_ERROR = 'app/LandingPage/THIRD_LISTING_ERROR';

export const NO_FEATURED_LISTING_REQUEST = 'app/LandingPage/NO_FEATURED_LISTING_REQUEST';
export const NO_FEATURED_LISTING_SUCCESS = 'app/LandingPage/NO_FEATURED_LISTING_SUCCESS';
export const NO_FEATURED_LISTING_ERROR = 'app/LandingPage/NO_FEATURED_LISTING_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/LandingPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/LandingPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/LandingPage/UPDATE_PROFILE_ERROR';

// ================ Reducer ================ //

const initialState = {
    featuredListings: [],
    featuredListingsInProgress: false,
    featuredListingsError: null,
    updateInProgress: false,
    updateProfileError: null,
};

const landingPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case FIRST_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case FIRST_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: state.featuredListings.find(l => l.id.uuid === payload.response.id.uuid) ? state.featuredListings : [...state.featuredListings, payload.response],
                featuredListingsInProgress: false,
            };
        case FIRST_FEATURED_LISTING_ERROR:
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        case SECOND_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case SECOND_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: state.featuredListings.find(l => l.id.uuid === payload.response.id.uuid) ? state.featuredListings : [...state.featuredListings, payload.response],
                featuredListingsInProgress: false,
            };
        case SECOND_FEATURED_LISTING_ERROR:
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        case THIRD_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case THIRD_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: state.featuredListings.find(l => l.id.uuid === payload.response.id.uuid) ? state.featuredListings : [...state.featuredListings, payload.response],
                featuredListingsInProgress: false,
            };
        case THIRD_FEATURED_LISTING_ERROR:
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        case NO_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case NO_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: payload.response,
                featuredListingsInProgress: false,
            };
        case NO_FEATURED_LISTING_ERROR:
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
            
        case UPDATE_PROFILE_REQUEST:
            return {
            ...state,
            updateInProgress: true,
            updateProfileError: null,
            };
        case UPDATE_PROFILE_SUCCESS:
            return {
            ...state,
            image: null,
            updateInProgress: false,
            };
        case UPDATE_PROFILE_ERROR:
            return {
            ...state,
            image: null,
            updateInProgress: false,
            updateProfileError: payload,
            };
        default:
            return state;
    }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const firstFeaturedListingRequest = () => ({
  type: FIRST_FEATURED_LISTING_REQUEST,
});

export const firstFeaturedListingSuccess = response => ({
  type: FIRST_FEATURED_LISTING_SUCCESS,
  payload: { response },
});

export const firstFeaturedListingError = e => ({
  type: FIRST_FEATURED_LISTING_ERROR,
  payload: e,
});

export const secondFeaturedListingRequest = () => ({
  type: SECOND_FEATURED_LISTING_REQUEST,
});

export const secondFeaturedListingSuccess = response => ({
  type: SECOND_FEATURED_LISTING_SUCCESS,
  payload: { response },
});

export const secondFeaturedListingError = e => ({
  type: SECOND_FEATURED_LISTING_ERROR,
  payload: e,
});

export const thirdFeaturedListingRequest = () => ({
  type: THIRD_FEATURED_LISTING_REQUEST,
});

export const thirdFeaturedListingSuccess = response => ({
  type: THIRD_FEATURED_LISTING_SUCCESS,
  payload: { response },
});

export const thirdFeaturedListingError = e => ({
  type: THIRD_FEATURED_LISTING_ERROR,
  payload: e,
});

export const noFeaturedListingRequest = () => ({
  type: NO_FEATURED_LISTING_REQUEST,
});

export const noFeaturedListingSuccess = response => ({
  type: NO_FEATURED_LISTING_SUCCESS,
  payload: { response },
});

export const noFeaturedListingError = e => ({
  type: NO_FEATURED_LISTING_ERROR,
  payload: e,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
    type: UPDATE_PROFILE_REQUEST,
    payload: { params },
  });
  export const updateProfileSuccess = result => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: result.data,
  });
  export const updateProfileError = error => ({
    type: UPDATE_PROFILE_ERROR,
    payload: error,
    error: true,
  });

export const firstFeaturedListing = (id) => (dispatch, getState, sdk) => {
  dispatch(firstFeaturedListingRequest());

  var listingId = new UUID(id);
  sdk.listings.show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      ...IMAGE_VARIANTS,
  }).then(response => {
      const listingRef = {
          id: response.data.data.id,
          type: response.data.data.type
      };
      dispatch(addMarketplaceEntities(response));
      dispatch(firstFeaturedListingSuccess(listingRef));
      return response;
  })
      .catch(e => dispatch(firstFeaturedListingError(storableError(e))));
};

export const secondFeaturedListing = (id) => (dispatch, getState, sdk) => {
  dispatch(secondFeaturedListingRequest());

  var listingId = new UUID(id);
  sdk.listings.show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      ...IMAGE_VARIANTS,
  }).then(response => {
      const listingRef = {
          id: response.data.data.id,
          type: response.data.data.type
      };
      dispatch(addMarketplaceEntities(response));
      dispatch(secondFeaturedListingSuccess(listingRef));
      return response;
  })
      .catch(e => dispatch(secondFeaturedListingError(storableError(e))));
};

export const thirdFeaturedListing = (id) => (dispatch, getState, sdk) => {
  dispatch(thirdFeaturedListingRequest());

  var listingId = new UUID(id);
  sdk.listings.show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      ...IMAGE_VARIANTS,
  }).then(response => {
      const listingRef = {
          id: response.data.data.id,
          type: response.data.data.type
      };
      dispatch(addMarketplaceEntities(response));
      dispatch(thirdFeaturedListingSuccess(listingRef));
      return response;
  })
      .catch(e => dispatch(thirdFeaturedListingError(storableError(e))));
};

export const queryUserListings = () => (dispatch, getState, sdk) => {
  dispatch(noFeaturedListingRequest());
  return sdk.listings
      .query({
          include: ['author', 'images'],
          'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
          perPage: 3,
      })
      .then(response => {
          // Pick only the id and type properties from the response listings
          const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
          dispatch(addMarketplaceEntities(response));
          dispatch(noFeaturedListingSuccess(listingRefs));
          return response;
      })
      .catch(e => dispatch(noFeaturedListingError(storableError(e))));
};

export const saveToWhishlist = listingId => {
    return (dispatch, getState, sdk) => {
      dispatch(updateProfileRequest());
  
      const { currentUser } = getState().user;
      const { attributes: userAttributes } = currentUser;
      const { whishlist = [] } = userAttributes.profile.privateData || {};
  
      // If listing is already saved, remove it from the list
      const isSaved = whishlist && whishlist.includes(listingId.uuid);
      const newSavedListings = isSaved
        ? whishlist.filter(id => id !== listingId.uuid)
        : [...whishlist, listingId.uuid];
  
      const queryParams = {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      };
  
      return sdk.currentUser
        .updateProfile({
          privateData: {
            whishlist: newSavedListings,
          }
        }, queryParams)
        .then(response => {
          dispatch(updateProfileSuccess(response));
  
          const entities = denormalisedResponseEntities(response);
          if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
          }
          const currentUser = entities[0];
  
          // Update current user in state.user.currentUser through user.duck.js
          dispatch(currentUserShowSuccess(currentUser));
        })
        .catch(e => dispatch(updateProfileError(storableError(e))));
    };
  };

export const fetchFeaturedListings = (listingIdOne, listingIdTwo, listingIdThree) => (dispatch, getState, sdk) => {
    dispatch(firstFeaturedListingRequest());
    dispatch(secondFeaturedListingRequest());
    dispatch(thirdFeaturedListingRequest());

    const firstListingId = listingIdOne ? new UUID(listingIdOne) : null;
    const secondListingId = listingIdTwo ? new UUID(listingIdTwo) : null;
    const thirdListingId = listingIdThree ? new UUID(listingIdThree) : null;

    const firstListing = firstListingId ? sdk.listings.show({
        id: firstListingId,
        include: ['author', 'author.profileImage', 'images'],
        ...IMAGE_VARIANTS,
    }) : null;

    const secondListing = secondListingId ? sdk.listings.show({
        id: secondListingId,
        include: ['author', 'author.profileImage', 'images'],
        ...IMAGE_VARIANTS,
    }) : null;

    const thirdListing = thirdListingId ? sdk.listings.show({
        id: thirdListingId,
        include: ['author', 'author.profileImage', 'images'],
        ...IMAGE_VARIANTS,
    }) : null;

    return Promise.all([firstListing, secondListing, thirdListing])
        .then(responses => {
            const listingRefs = responses.map(response => {
                const listingRef = {
                    id: response.data.data.id,
                    type: response.data.data.type
                };
                dispatch(addMarketplaceEntities(response));
                return listingRef;
            });
            dispatch(firstFeaturedListingSuccess(listingRefs[0]));
            dispatch(secondFeaturedListingSuccess(listingRefs[1]));
            dispatch(thirdFeaturedListingSuccess(listingRefs[2]));
            return responses;
        })
        .catch(e => {
            dispatch(firstFeaturedListingError(storableError(e)));
            dispatch(secondFeaturedListingError(storableError(e)));
            dispatch(thirdFeaturedListingError(storableError(e)));
            throw e;
        }
        );
}

export const loadData = (params, search) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  const listingsIds = [];

    if(firstId !== "" && firstId !== undefined) {
        listingsIds.push(firstId);
    }
    if(secondId !== "" && secondId !== undefined) {
        listingsIds.push(secondId);
    }
    if(thirdId !== "" && thirdId !== undefined) {
        listingsIds.push(thirdId);
    }

    if(listingsIds.length === 4) {
        return Promise.all([
            dispatch(firstFeaturedListing(listingsIds[0])), // get first featured listing
            dispatch(secondFeaturedListing(listingsIds[1])), // get second featured listing
            dispatch(thirdFeaturedListing(listingsIds[2])), // get third featured listing
            dispatch(fetchPageAssets(pageAsset, true))
        ]);
    }

    if (listingsIds.length === 3) {
        return Promise.all([
            dispatch(firstFeaturedListing(listingsIds[0])), // get first featured listing
            dispatch(secondFeaturedListing(listingsIds[1])), // get second featured listing
            dispatch(thirdFeaturedListing(listingsIds[2])), // get third featured listing
            dispatch(fetchPageAssets(pageAsset, true))
        ]);
    }

    if (listingsIds.length === 2) {
        return Promise.all([
            dispatch(firstFeaturedListing(listingsIds[0])), // get first featured listing
            dispatch(secondFeaturedListing(listingsIds[1])), // get second featured listing
            dispatch(fetchPageAssets(pageAsset, true))
        ]);
    }

    if (listingsIds.length === 1) {
        return Promise.all([
            dispatch(firstFeaturedListing(listingsIds[0])), // get first featured listing
            dispatch(fetchPageAssets(pageAsset, true))
        ]);
    }
    if (listingsIds.length === 0) {
        return Promise.all([
            dispatch(queryUserListings()),
            dispatch(fetchPageAssets(pageAsset, true))
        ]);
    }
};
