import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';

import css from './Heading.module.css';

// Make it possible to use styling of H1, while the actual element is `<h2>`
const Heading = props => {
  const { className, rootClassName, as, tagRef, ...otherProps } = props;
  const Tag = as || 'h2';
  const classes = classNames(rootClassName, className);

  // Check for alignment classes
  const alignmentFormat = /\{\{(left|center|right)\}\}/g;
  const alignmentMatch = alignmentFormat.exec(otherProps.children);
  if (alignmentMatch !== null) {
    const alignment = alignmentMatch[1];
    if (alignment === "left") {
      otherProps.className = classNames(classes, css.left);
    } else if (alignment === "center") {
      otherProps.className = classNames(classes, css.center);
    } else if (alignment === "right") {
      otherProps.className = classNames(classes, css.right);
    }
  }

  // Remove the alignment classes from the children
  otherProps.children = typeof otherProps.children === 'string' ? otherProps.children.replace(alignmentFormat, "") : "";

  // Check for matches of the form {{color}}text{{color}}
  const keyFormat = /\{\{(green|blue|white|black|marketColor)\}\}([^]*?)\{\{\1\}\}/g;
  const parts = [];
  let match;

  let lastIndex = 0;
  while ((match = keyFormat.exec(otherProps.children)) !== null) {
    const textBeforeMatch = otherProps.children.slice(lastIndex, match.index);
    if (textBeforeMatch) {
      parts.push({
        color: "",
        text: textBeforeMatch
      });
    }
    parts.push({
      color: match[1],
      text: match[2]
    });
    lastIndex = match.index + match[0].length;
  }

  const textAfterLastMatch = otherProps.children.slice(lastIndex);
  if (textAfterLastMatch) {
    parts.push({
      color: "",
      text: textAfterLastMatch
    });
  }

  const highlightedText = parts.map((part, index) => {
    if (part.color === "green") {
      return (
        <span key={index} className={css.greenText}>
          {part.text}
        </span>
      );
    } else if (part.color === "blue") {
      return (
        <span key={index} className={css.blueText}>
          {part.text}
        </span>
      );
    } else if (part.color === "white") {
      return (
        <span key={index} className={css.whiteText}>
          {part.text}
        </span>
      );
    } else if (part.color === "black") {
      return (
        <span key={index} className={css.blackText}>
          {part.text}
        </span>
      );
    } else if (part.color === "marketColor") {
      return (
        <span key={index} className={css.marketColorText}>
          {part.text}
        </span>
      );
    } else {
      return part.text;
    }
  });

  return (
    <Tag className={classes} ref={tagRef} {...otherProps}>
      {highlightedText}
    </Tag>
  );

};

const defaultPropsHeading = {
  rootClassName: null,
  className: null,
  as: null,
};

const propTypesHeading = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
  as: string,
};

export const H1 = React.forwardRef((props, ref) => {
  const { rootClassName: rootClass, as, ...otherProps } = props;
  return (
    <Heading rootClassName={rootClass || css.h1} as={as || 'h1'} tagRef={ref} {...otherProps} />
  );
});
H1.displayName = 'H1';
H1.defaultProps = defaultPropsHeading;
H1.propTypes = propTypesHeading;

export const H2 = React.forwardRef((props, ref) => {
  const { rootClassName: rootClass, as, ...otherProps } = props;
  return (
    <Heading rootClassName={rootClass || css.h2} as={as || 'h2'} tagRef={ref} {...otherProps} />
  );
});
H2.displayName = 'H2';
H2.defaultProps = defaultPropsHeading;
H2.propTypes = propTypesHeading;

export const H3 = React.forwardRef((props, ref) => {
  const { rootClassName: rootClass, as, ...otherProps } = props;
  return (
    <Heading rootClassName={rootClass || css.h3} as={as || 'h3'} tagRef={ref} {...otherProps} />
  );
});
H3.displayName = 'H3';
H3.defaultProps = defaultPropsHeading;
H3.propTypes = propTypesHeading;

export const H4 = React.forwardRef((props, ref) => {
  const { rootClassName: rootClass, as, ...otherProps } = props;
  return (
    <Heading rootClassName={rootClass || css.h4} as={as || 'h4'} tagRef={ref} {...otherProps} />
  );
});
H4.displayName = 'H4';
H4.defaultProps = defaultPropsHeading;
H4.propTypes = propTypesHeading;

export const H5 = React.forwardRef((props, ref) => {
  const { rootClassName: rootClass, as, ...otherProps } = props;
  return (
    <Heading rootClassName={rootClass || css.h5} as={as || 'h5'} tagRef={ref} {...otherProps} />
  );
});
H5.displayName = 'H5';
H5.defaultProps = defaultPropsHeading;
H5.propTypes = propTypesHeading;

export const H6 = React.forwardRef((props, ref) => {
  const { rootClassName: rootClass, as, ...otherProps } = props;
  return (
    <Heading rootClassName={rootClass || css.h6} as={as || 'h6'} tagRef={ref} {...otherProps} />
  );
});
H6.displayName = 'H6';
H6.defaultProps = defaultPropsHeading;
H6.propTypes = propTypesHeading;
