import React from 'react';
import { arrayOf, func, node, oneOf, shape, string } from 'prop-types';

// Block components
import BlockDefault from './BlockDefault';
import BlockArticleHorizontal from './BlockArticleHorizontal';
import BlockIconCard from './BlockIconCard';
import BlockImageCard from './BlockImageCard';
import BlockHorizontalImageCard from './BlockHorizontalImageCard';
import BlockChecklistItem from './BlockChecklistItem/BlockChecklistItem';
import BlockAccordionItem from './BlockAccordionItem/BlockAccordionItem';
import BlockPaddingArticle from './BlockPaddingArticle/BlockPaddingArticle';
import BlockReviewItem from './BlockReviewItem/BlockReviewItem';
import BlockPageList from './BlockPageList/BlockPageList';
import BlockContactForm from './BlockContactForm/BlockContactForm';
import BlockBlogSection from './BlockBlogSection/BlockBlogSection';

///////////////////////////////////////////
// Mapping of block types and components //
///////////////////////////////////////////

const defaultBlockComponents = {
  defaultBlock: { component: BlockDefault },
  "article-horizontal": { component: BlockArticleHorizontal },
  "icon-card": { component: BlockIconCard },
  "image-card": { component: BlockImageCard },
  "image-card-blog": { component: BlockImageCard },
  "horizontal-image-card": { component: BlockHorizontalImageCard },
  "checklist-item": { component: BlockChecklistItem },
  "accordion-item": { component: BlockAccordionItem },
  "padding-section": { component: BlockPaddingArticle },
  "padding-section-dark": { component: BlockPaddingArticle },
  "padding-section-light": { component: BlockPaddingArticle },
  "padding-section-marketplace": { component: BlockPaddingArticle },
  "padding-section-image": { component: BlockPaddingArticle },
  "review-item": { component: BlockReviewItem },
  "page-list": { component: BlockPageList},
  "contact-form": { component: BlockContactForm },
  "blog-section-header": { component: BlockBlogSection },
  "blog-section-footer": { component: BlockBlogSection },
};

////////////////////
// Blocks builder //
////////////////////

const BlockBuilder = props => {
  const { blocks, options, ...otherProps } = props;

  // Extract block & field component mappings from props
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const { blockComponents, fieldComponents } = options || {};
  const blockOptionsMaybe = fieldComponents ? { options: { fieldComponents } } : {};

  // If there's no block, we can't render the correct block component
  if (!blocks || blocks.length === 0) {
    return null;
  }

  // Selection of Block components
  // Combine component-mapping from props together with the default one:
  const components = { ...defaultBlockComponents, ...blockComponents };

  return (
    <>
      {blocks.map((block, index) => {
        const config = components[block.blockId] || components[block.blockType];
        const Block = config?.component;
        if (Block) {
          return <Block key={block.blockId + index} {...block} {...blockOptionsMaybe} {...otherProps} index={index}/>;
        } else {
          // If the block type is unknown, the app can't know what to render
          console.warn(`Unknown block type (${block.blockType}) detected.`);
          return null;
        }
      })}
    </>
  );
};

const propTypeBlock = shape({
  blockId: string.isRequired,
  blockType: oneOf(['defaultBlock']).isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
});

BlockBuilder.defaultProps = {
  blocks: [],
  options: null,
  responsiveImageSizes: null,
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
};

BlockBuilder.propTypes = {
  blocks: arrayOf(propTypeBlock),
  options: propTypeOption,
  responsiveImageSizes: string,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
};

export default BlockBuilder;
