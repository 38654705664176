import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';

import Field, { hasDataInFields } from '../../containers/PageBuilder/Field';
import { Button, Modal, SecondaryButton, IconCircleCheck, } from '..'
import { SearchForm } from '../../forms';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import logo from '../../assets/LandingPage/banner.png'
import bannerMobile from '../../assets/LandingPage/bannerMobile.png'
import newsletterImage from '../../assets/LandingPage/newsletter.jpg'

import css from './SectionHero.module.css';
import { addContactToMailchimp } from '../../util/api';

const SectionHero = props => {

  const [mounted, setMounted] = useState(false);
  const [showNewsletter, setShowNewsletter] = useState(false);
  const [newsletterInProgess, setNewsletterInProgess] = useState(false);
  const [newsletterSuccess, setNewsletterSuccess] = useState(false);

  const {
    title,
    description,
    callToAction,
    options,
    history,
    isAuthenticated,
    currentUser,
  } = props;

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setTimeout(() => {
        setShowNewsletter(true);
      }, 15000);
    }
  }, [currentUser]);
  console.log('mounted', mounted)
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const onSearchSubmit = values => {
    const { search, selectedPlace } = values;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...originMaybe,
      address: search,
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  return (
    <div className={css.customContainerHero}>
      <div className={css.customLeftTabHero}>
        {hasHeaderFields && (
          <div className={classNames(css.customTextHero)}>
            <Field data={title} className={classNames(css.heroMainTitle)} options={fieldOptions} />
            <Field data={description} className={classNames(css.heroSubTitle)} options={fieldOptions} />
            <Field data={callToAction} className={css.ctaButton} options={fieldOptions} />
          </div>
        )}
      </div>
      <div className={css.customRightTabHero}>
        <img src={logo} alt="logo" className={css.banner} />
        <img src={bannerMobile} alt="logo" className={css.bannerMobile} />
      </div>
      <div className={css.searchContainer}>
        <div className={css.searchBox}>
          <SearchForm
            className={css.searchLink}
            onSubmit={onSearchSubmit}
          />
        </div>
      </div>
      <Modal
        id="newsletter"
        isOpen={showNewsletter}
        onClose={() => setShowNewsletter(false)}
        onManageDisableScrolling={() => { }}
        containerClassName={css.modal}
        contentClassName={css.modalContent}
        closeButtonMessage=""
      >
        <div className={css.newsletterLeft}>
          <img src={newsletterImage} alt="logo" className={css.newsletterImage} />
        </div>
        <div className={css.newsletterRight}>
          {!newsletterSuccess && (
            <>
              <h2 className={css.newsletterTitle}>Never miss an update!</h2>
              <p className={css.newsletterDescription}>Subscribe to our newsletter to stay updated on the Homebase community and never miss any promotions!</p>
              <form
                className={css.newsletterRightContent}
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setNewsletterInProgess(true);
                  // get the email
                  var email = e.target[0].value;

                  // add the email to the newsletter list
                  addContactToMailchimp({ email, isAuthenticated }).then((res) => {
                    if (res.success) {
                      // show success message
                      setNewsletterInProgess(false);
                      setNewsletterSuccess(true);
                    } else {
                      // show error message
                      setNewsletterInProgess(false);
                    }
                  })
                    .catch((err) => {
                      // show error message
                      setNewsletterInProgess(false);
                    });
                }}
              >
                <input type="email" placeholder="Your email address" className={css.newsletterInput} />
                <Button
                  className={css.newsletterButton}
                  type="submit"
                  inProgress={newsletterInProgess}
                  disabled={newsletterInProgess}
                >
                  Subscribe
                </Button>
              </form>
            </>
          )}
          {newsletterSuccess && (
            <div className={css.successContent}>
              <IconCircleCheck className={css.modalIcon} />
              <h2 className={css.modalTitle}>Thank you for subscribing.</h2>
              <SecondaryButton
                className={css.modalButton}
                onClick={() => {
                  setNewsletterSuccess(false);
                  setShowNewsletter(false);
                }}
              >
                Close
              </SecondaryButton>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
)(SectionHero);