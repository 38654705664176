import React from 'react'
import classNames from 'classnames';
import { NamedLink, ListingCard } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import css from './TopPicks.module.css'

export default function TopPicks(props) {

  const { 
    rootClassName, 
    className, 
    listings, 
    intl, 
    history,
    isAuthenticated,
    currentUser,
    onFavoriteListing,
    updateInProgress,
    updateProfileError
  } = props
  const classes = classNames(rootClassName || css.root, className)

  return (
    <div className={classes}>
      <div className={css.ourTopPicks}>
        <div className={css.ourTopPicksTexts}>
          <h2 className={css.otpTitle}>
            <FormattedMessage id="TopPicks.title" />
          </h2>
          <div className={css.otpCardsContainer}>
            {listings.map((top, index) => {
              return (
                <ListingCard 
                  int={intl} 
                  listing={top} 
                  key={index} 
                  className={css.card} 
                  history={history}
                  updateInProgress={updateInProgress}
                  updateProfileError={updateProfileError}
                  onFavoriteListing={onFavoriteListing}
                  isAuthenticated={isAuthenticated}
                  currentUser={currentUser}
                />
              )
            })}
          </div>
        </div>
        <NamedLink name="SearchPage" className={css.viewAllButton}>
          <FormattedMessage id="TopPicks.viewAll" />
        </NamedLink>
      </div>
    </div>
  )
}
