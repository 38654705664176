import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { AddImages, PrimaryButton as Button, Form, IconAdd, ValidationError } from '../../components';

import css from './EditListingPhotosForm.module.css';

const ACCEPT_IMAGES = 'image/*';

export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable(array, fromIndex, toIndex) {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
}

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUploadRequested: false,
      images: this.props.images
    };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  onImageUploadHandler(file, order) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          this.setState({ imageUploadRequested: false });

          if (order === "first") {
            // add the image to the beginning of the array
            this.props.setFirstImageId(this.props.flexImages[this.props.flexImages.length - 1].imageId.uuid);
          } else if (order === "second") {
            // add the image to the second position of the array
            this.props.setSecondImageId(this.props.flexImages[this.props.flexImages.length - 1].imageId.uuid);
          }
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  handleOnSortEnd = ({ oldIndex, newIndex }) => {
    const { images } = this.state;
    const newImages = arrayMoveImmutable(images, oldIndex, newIndex);
    this.setState({ images: newImages })
    this.props.onSortEnd(newImages)
  }

  componentDidUpdate() {
    // if the images in the state are not equal to the images in the props, update the state
    if (!isEqual(this.state.images, this.props.images)) {
      this.setState({ images: this.props.images })
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={{ images: this.state.images }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            backButtonText,
            redirectAfterPrevTab,
            firstImageId,
            secondImageId,
            backToListing,
            backToListingText,
            isNewListingFlow
          } = formRenderProps;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseImage" />
              </span>
            </span>
          );

          const secondChooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImageTwo}>
                <IconAdd className={css.addIcon} />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid || disabled || submitInProgress || imageUploadRequested || ready;

          const classes = classNames(css.root, className);

          const firstImage = firstImageId && images.find(img => img.id?.uuid === firstImageId || img.imageId?.uuid === firstImageId);
          const secondImage = secondImageId && images.find(img => img.id?.uuid === secondImageId || img.imageId?.uuid === secondImageId);

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >

              {imageUploadRequested && (
                <div className={css.loaderWrapper}>
                  <div className={css.loader} />
                </div>
              )}

              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                </p>
              ) : null}

              <div className={css.firstFields}>
                <div className={css.fieldWrapper}>
                  <div className={css.label}>
                    <FormattedMessage id="EditListingPhotosForm.workspaceImage" values={{ required: <span className={css.labelGreen}>*</span> }} />
                  </div>
                  <AddImages
                    className={css.imagesField}
                    images={firstImage ? [firstImage] : []}
                    displayRule={index => index === 0}
                    thumbnailClassName={css.thumbnail}
                    savedImageAltText={intl.formatMessage({
                      id: 'EditListingPhotosForm.savedImageAltText',
                    })}
                    onRemoveImage={onRemoveImage}
                  >
                    <Field
                      id="addImage"
                      name="addImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`addImage`, file);
                          form.blur(`addImage`);
                          onImageUploadHandler(file, "first");
                        };
                        console.log(fieldDisabled)
                        const inputProps = { accept, id: name, name, onChange, type };
                        return (
                          <div className={classNames(css.addImageWrapper, {
                            [css.addImageWrapperDisabled]: firstImage,
                          })}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>

                    <Field
                      component={props => {
                        const { input, meta } = props;
                        return (
                          <div className={css.imageRequiredWrapper}>
                            <input {...input} />
                            <ValidationError fieldMeta={meta} />
                          </div>
                        );
                      }}
                      name="images"
                      type="hidden"
                      validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                    />
                  </AddImages>

                  <p className={css.labelGreen}>
                    <FormattedMessage id="EditListingPhotosForm.note" />
                  </p>
                  <p className={css.tip}>
                    <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
                  </p>
                </div>
                <div className={css.fieldWrapper}>
                  <div className={css.label}>
                    <FormattedMessage id="EditListingPhotosForm.windowViewImage" />
                  </div>
                  <AddImages
                    className={css.imagesField}
                    images={secondImage ? [secondImage] : []}
                    displayRule={index => index === 0}
                    thumbnailClassName={css.thumbnail}
                    savedImageAltText={intl.formatMessage({
                      id: 'EditListingPhotosForm.savedImageAltText',
                    })}
                    onRemoveImage={onRemoveImage}
                  >
                    <Field
                      id="addImage2"
                      name="addImage2"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`addImage2`, file);
                          form.blur(`addImage2`);
                          onImageUploadHandler(file, "second");
                        };
                        const inputProps = { accept, id: name, name, onChange, type };
                        return (
                          <div className={classNames(css.addImageWrapper, {
                            [css.addImageWrapperDisabled]: secondImage,
                          })}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>

                    <Field
                      component={props => {
                        const { input, meta } = props;
                        return (
                          <div className={css.imageRequiredWrapper}>
                            <input {...input} />
                            <ValidationError fieldMeta={meta} />
                          </div>
                        );
                      }}
                      name="images"
                      type="hidden"
                      validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                    />
                  </AddImages>
                  <p className={css.labelGreen}>
                    <FormattedMessage id="EditListingPhotosForm.note" />
                  </p>
                  <p className={css.tip}>
                    <FormattedMessage id="EditListingPhotosForm.windowViewTip" />
                  </p>
                </div>
              </div>

              {uploadImageFailed}

              <div className={css.label} style={{ marginTop: "36px" }}>
                <FormattedMessage id="EditListingPhotosForm.additionalPhotos" />
              </div>

              <AddImages
                className={css.imagesField}
                images={images}
                displayRule={index => firstImageId && secondImageId ? index > 1 : !firstImageId || !secondImageId ? index > 0 : true}
                thumbnailClassName={css.secondThumbnail}
                savedImageAltText={intl.formatMessage({
                  id: 'EditListingPhotosForm.savedImageAltText',
                })}
                onRemoveImage={onRemoveImage}
                onSortEnd={this.handleOnSortEnd}
                axis='x, y'
              >
                <Field
                  id="addImage3"
                  name="addImage3"
                  accept={ACCEPT_IMAGES}
                  form={null}
                  label={secondChooseImageText}
                  type="file"
                  disabled={imageUploadRequested}
                  multiple
                >
                  {fieldprops => {
                    const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                    const { name, type } = input;
                    const onChange = e => {
                      console.log(e.target.files)
                      for (let i = 0; i < e.target.files.length; i++) {
                        const file = e.target.files[i];
                        form.change(`addImage3`, file);
                        form.blur(`addImage3`);
                        onImageUploadHandler(file);
                      }
                    };
                    const inputProps = { accept, id: name, name, onChange, type };
                    return (
                      <div className={classNames(css.secondAddImageWrapper, {
                        [css.addImageWrapperDisabled]: images && images.length > 20,
                      })}>
                        <div className={css.aspectRatioWrapper}>
                          {fieldDisabled ? null : (
                            <input {...inputProps} className={css.addImageInput} multiple />
                          )}
                          <label htmlFor={name} className={css.addImage}>
                            {label}
                          </label>
                        </div>
                      </div>
                    );
                  }}
                </Field>

                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} multiple />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                  validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                />
              </AddImages>
              {publishListingFailed}
              {showListingFailed}

              <div className={css.buttonWrapper}>
                <Button
                  className={css.backButton}
                  type="button"
                  inProgress={submitInProgress}
                  onClick={redirectAfterPrevTab}
                >
                  {backButtonText}
                </Button>
                <div className={css.buttonWrapper}>
                  {!isNewListingFlow && (
                    <Button
                      className={css.listingButton}
                      type="button"
                      inProgress={submitInProgress}
                      onClick={backToListing}
                    >
                      {backToListingText}
                    </Button>
                  )}
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
